"use client";

import { AxiosError } from "axios";
import { createContext, useCallback, useEffect, useState } from "react";
import AxiosInterceptorWrapper, {
  IAxiosInterceptors,
} from "../AxiosInterceptorWrapper";
import { retry, retryAfter } from "../../utils/retry";
import {
  fetchOrCreateSession,
  updateRefreshToken,
  useLogin,
  useInitialRefreshTokenState,
} from "../../utils";
import { getLang } from "@hopper-b2b/i18n";
import { getRefreshToken } from "../../api";
import { provideAuthTokens } from "libs/utilities/src/lib/mobileWebApp";
import dayjs from "dayjs";
import {
  useAuthProvider,
  useIsServerSideRendering,
} from "@hopper-b2b/utilities";

export type AuthorizationTokenState = "OK" | "UNAUTHORIZED";
export type SessionState = "UNINITIALIZED" | "INITIALIZING" | "INITIALIZED";

export const UnauthorizedContext = createContext<AuthorizationTokenState>("OK");

type UnauthorizationProviderProps = React.PropsWithChildren<
  {
    loadingFallback?: React.ReactNode;
  } & IAxiosInterceptors
>;

export const UnauthorizationProvider = ({
  children,
  recaptchaActionKey,
  version,
  loadingFallback,
}: UnauthorizationProviderProps) => {
  const [authState, setAuthState] = useState<AuthorizationTokenState>("OK");
  const [sessionState, setSessionState] =
    useState<SessionState>("UNINITIALIZED");
  const onLogin = useLogin();
  const { state } = useAuthProvider();
  const sessionInfo = state?.sessionInfo;
  const userState = sessionInfo?.userInfo.userState;
  const initialRefreshTokenState = useInitialRefreshTokenState();
  const isServerSideRendering = useIsServerSideRendering();

  useEffect(() => {
    if (
      !sessionInfo &&
      initialRefreshTokenState !== "LOADING" &&
      sessionState === "UNINITIALIZED"
    ) {
      const lang = getLang();
      setSessionState("INITIALIZING");
      fetchOrCreateSession(onLogin, lang).finally(() => {
        setSessionState("INITIALIZED");
      });
    }
  }, [onLogin, initialRefreshTokenState, sessionInfo, sessionState]);

  const errorAction = useCallback(
    (e: AxiosError) => {
      switch (e.response?.status) {
        case 401:
          // Only attempt a refresh if we have a refreshToken.
          if (getRefreshToken()) {
            // We don't want special error handling if the refreshToken is invalid.
            // Any refresh failure should fail the request we're retrying.
            const updateToken = () => {
              return updateRefreshToken().then((r) => {
                if (r === "INVALID") {
                  return Promise.reject(e);
                } else {
                  return r;
                }
              });
            };
            return retryAfter(updateToken, e)
              .then((res) => {
                const refreshTokens = res.retryResult;

                if (userState === "Verified") {
                  provideAuthTokens(
                    refreshTokens.accessToken,
                    refreshTokens.refreshToken,
                    dayjs().add(45, "minute").unix()
                  );
                }
                setAuthState("OK");
                return res.originalResult;
              })
              .catch(() => {
                setAuthState("UNAUTHORIZED");
                return Promise.reject(e);
              });
          } else {
            return Promise.reject(e);
          }
        case 403:
          return retry(e);
        default:
          return Promise.reject(e);
      }
    },
    [userState]
  );

  const isInitialized =
    isServerSideRendering ||
    state?.sessionInfo ||
    sessionState === "INITIALIZED";

  return (
    <UnauthorizedContext.Provider value={authState}>
      <AxiosInterceptorWrapper
        recaptchaActionKey={recaptchaActionKey}
        version={version}
        errorAction={errorAction}
      />
      {!isInitialized ? loadingFallback : children}
    </UnauthorizedContext.Provider>
  );
};
