import { FiatPrice, TripSlice } from "@b2bportal/air-shopping-api";
import { DateTime, Prices, Uuid } from "../../common";
import { AlgomerchTag } from "../../flights/shopping";
import { AirlineMap, AirportMap, CityMap } from "../../trips-module/itinerary";
import { FlightTags } from "./shop-summary";

export interface TripSummary {
  domestic?: string;
  tripId: string;
  outgoingSlice: TripSlice;
  returningSlice?: TripSlice;
  context: TripSummaryContext;
  tripFares: TripFare[];
}

export interface TripSummaryContext {
  airports: AirportMap;
  airlines: AirlineMap;
  cities?: CityMap;
}

export interface SegmentAirline {
  code: string;
  name: string;
}

export interface TripSegment {
  airlineCode: string;
  airlineName: string;
  flightNumber: string;
  marketingAirline: SegmentAirline;
  operatingAirline: SegmentAirline;
  departureTime: DateTime;
  originName: string;
  originCode: string;
  arrivalTime: DateTime;
  destinationName: string;
  destinationCode: string;
  plusDays?: number;
  stopoverDurationMinutes?: number;
  zonedDepartureTime?: string;
  zonedArrivalTime?: string;
  isSelfTransferLayover?: boolean;
}

export interface FareScore {
  comfort: number;
  value: number;
  isBest: boolean;
  isCheapest: boolean;
  isBestQuality: boolean;
  isFastest: boolean;
}

export interface FareShelfInfo {
  outgoing: FareShelf;
  returning?: FareShelf;
}

export interface FareShelf {
  rating: number;
  brandName: string;
  shortBrandName: string;
  opaqueMapperStep?: string;
}

export interface TripFare {
  amount: Prices;
  fareId: Uuid;
  fareScore: FareScore;
  fareShelf?: FareShelfInfo;
  isMultiTicket: boolean;
}

export interface IFlightGridFareInfo {
  fareId: string;
  fareName: string;
  fareClass?: string;
  price: string;
  rawPrice: number;
  fiatPrice: FiatPrice;
  tags: AlgomerchTag[];
  reward?: string;
}

type typicalObj = {
  [key in string]: IFlightGridFareInfo | null;
};

export interface IFlightGridFares extends typicalObj {
  basic: IFlightGridFareInfo | null;
  standard: IFlightGridFareInfo | null;
  enhanced: IFlightGridFareInfo | null;
  premium: IFlightGridFareInfo | null;
  luxury: IFlightGridFareInfo | null;
}

export function getFlightGridFares(
  tripFares: TripFare[],
  outgoingRating?: number,
  isReturn?: boolean,
  rewardsKey?: string
) {
  // TODO create currency mapping function
  const fares: IFlightGridFares = {
    basic: null,
    standard: null,
    premium: null,
    enhanced: null,
    luxury: null,
  };
  const filteredFares =
    outgoingRating !== undefined
      ? tripFares.filter((f) => f.fareShelf?.outgoing.rating === outgoingRating)
      : tripFares;
  filteredFares.forEach((fare) => {
    const fareShelf = isReturn
      ? fare.fareShelf?.returning
      : fare.fareShelf?.outgoing;
    const tags = getTags(fare.fareScore);
    const reward = fare.amount.rewards?.[rewardsKey ?? ""];

    const gridFare: IFlightGridFareInfo = {
      fareId: fare.fareId,
      fareName: fareShelf?.shortBrandName ?? "",
      fareClass: fareShelf?.brandName,
      price: `${fare.amount.fiat.currencySymbol}${Math.round(
        fare.amount.fiat.value
      )}`,
      rawPrice: fare.amount.fiat.value,
      fiatPrice: fare.amount.fiat,
      tags,
    };

    if (reward) {
      // TODO: possibly move getRewardText and other Prices related helpers to Redmond
      const isFiatCurrency = reward.currency === "$";
      const formattedValue = isFiatCurrency
        ? reward.value.toFixed(2)
        : Math.ceil(reward.value).toLocaleString();

      gridFare.reward = isFiatCurrency
        ? `${reward.currency}${formattedValue}`
        : `${formattedValue} ${reward.currency}`;
    }

    switch (fareShelf?.rating) {
      case 0:
        fares.basic = gridFare;
        break;
      case 1:
        fares.standard = gridFare;
        break;
      case 2:
        fares.enhanced = gridFare;
        break;
      case 3:
        fares.premium = gridFare;
        break;
      case 4:
        fares.luxury = gridFare;
        break;
    }
  });
  return fares;
}

export const getHopperFareRatingName = (rating?: number) => {
  switch (rating) {
    case 0:
      return "BasicEconomy";
    case 1:
      return "Economy";
    case 2:
      return "PremiumEconomy";
    case 3:
      return "Business";
    case 4:
      return "First";
    default:
      return "";
  }
};

export function getTags(fareScore: FareScore | FlightTags) {
  const tags: AlgomerchTag[] = [];
  if (fareScore.isCheapest) {
    tags.push(AlgomerchTag.Cheapest);
  }
  if (fareScore.isFastest) {
    tags.push(AlgomerchTag.Fastest);
  }
  if (fareScore.isBest) {
    tags.push(AlgomerchTag.BestFlight);
  }
  if (fareScore.isBestQuality) {
    tags.push(AlgomerchTag.BestQuality);
  }
  return tags;
}

export const mapAlgomerchTexts: { [key in AlgomerchTag]: string } = {
  BestFlight: "Best Overall",
  Cheapest: "Best Price",
  BestQuality: "Best Quality",
  Fastest: "Fastest",
};

export const mapI18nAlgomerchText = {
  BestFlight: "algomerch.bestFlightTag.title",
  Cheapest: "algomerch.cheapestTag.title",
  BestQuality: "algomerch.bestQualityTag.title",
  Fastest: "algomerch.fastestTag.title",
};
