import { UserState } from "@b2bportal/auth-api";
import { TrackEventRequest } from "@b2bportal/event-tracking-api";
import { getDeviceId, trackEvent } from "@hopper-b2b/api";
import { useExperiment, useExperiments } from "@hopper-b2b/experiments";
import { IApiConfig, TrackingPropertiesType } from "@hopper-b2b/types";
import {
  getBrandAttribution,
  getCurrency,
  currentBrand as getCurrentBrand,
  getDeviceData,
  getMobileAppBuildNumber,
  getMobileAppOs,
  getMobileAppPlatform,
  getMobileAppVersion,
  getReactAppVersion,
  getUserDeviceData,
  getUtmParameters,
  internalGetLang,
  isAtHotelBrand,
  isHopperAppWebView,
  defaultCurrency as maybeDefaultCurrency,
  useAuthProvider,
  useIsServerSideRendering,
  USER_SOURCE_KEY,
  useUserSource,
} from "@hopper-b2b/utilities";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getScreen } from "../utils";

export type UseTrackEventProps = Omit<TrackEventRequest, "properties"> & {
  properties?: object;
};

const TENANT = "hopper-app";

export const useTrackEvent = () => {
  const locale = internalGetLang();
  const userSource = useUserSource();
  const userInfoContext = useAuthProvider();
  const isServerSideRendering = useIsServerSideRendering();
  const { initialized, trackingProperties: experimentsTrackingProperties } =
    useExperiments();

  const [userDeviceData, setUserDeviceData] = useState<object | null>(null);
  const [referrer, setReferrer] = useState<string | null>(null);
  const [eventMap, setEventMap] = useState(new Set<string>());
  const version = useMemo(getReactAppVersion, []);

  useEffect(() => {
    setUserDeviceData(getUserDeviceData());
    setReferrer(document.referrer);
  }, []);

  const shouldDefaultCurrency =
    !!useExperiment("WebHotelPreferredCurrencyDefaulting") && isAtHotelBrand();
  const defaultCurrency = maybeDefaultCurrency(
    shouldDefaultCurrency,
    userInfoContext?.state?.sessionInfo?.userInfo?.defaultCurrency
  );
  const currentBrand = getCurrentBrand();
  const currency = useMemo(
    () => getCurrency(defaultCurrency) || "USD",
    [defaultCurrency]
  );

  const deviceProperties = useMemo(
    () => ({
      ...userDeviceData,
      device_type: getDeviceData().type,
    }),
    [userDeviceData]
  );

  const pageProperties = useMemo(
    () => ({
      referrer_url: referrer,
      referrer_url_host: referrer ? new URL(referrer).hostname : "",
      page_referrer: referrer,
      page_url: isServerSideRendering ? null : window?.location.pathname ?? "",
      screen: getScreen(),
    }),
    [referrer, isServerSideRendering]
  );

  const userSourceProperties = useMemo(
    () =>
      userSource
        ? { [USER_SOURCE_KEY]: userSource, utm_source: userSource }
        : {},
    [userSource]
  );

  const utmProperties = () => {
    const utmParameters = getUtmParameters();
    return {
      utm_source: utmParameters.utmSource,
      utm_medium: utmParameters.utmMedium,
      utm_campaign: utmParameters.utmCampaign,
    };
  };

  const mobileWebAppTrackingProperties: () => TrackingPropertiesType = () => {
    return isHopperAppWebView()
      ? {
          $app_version: getMobileAppVersion(),
          $app_version_string: getMobileAppVersion(),
          $app_build_number: getMobileAppBuildNumber(),
          deviceID: getDeviceId(),
          $os: getMobileAppOs(),
          platform: getMobileAppPlatform(),
        }
      : {};
  };
  return useCallback(
    async (
      event: UseTrackEventProps,
      apiConfig?: IApiConfig,
      keepAlive?: boolean,
      triggerOnce?: boolean
    ) => {
      if (initialized) {
        if (triggerOnce) {
          if (eventMap.has(event.eventName)) {
            return;
          }
          setEventMap((prev) => new Set([...prev, event.eventName]));
        }
        return trackEvent(
          {
            ...event,
            properties: {
              portal_brand: currentBrand,
              portal_brand_attribution: getBrandAttribution(),
              is_agent_session:
                !!userInfoContext?.state?.sessionInfo?.delegatedTo,
              delegated_to: userInfoContext?.state?.sessionInfo?.delegatedTo,
              guest_user:
                userInfoContext?.state?.sessionInfo?.userInfo?.userState ===
                UserState.Guest,
              signed_in:
                userInfoContext?.state?.sessionInfo?.userScope?.isSignedIn,
              currency,
              tenant: TENANT,
              locale,
              preferred_languages: (navigator?.languages ?? []).join(","),
              app_version: version,
              $app_release: version,
              ...deviceProperties,
              ...pageProperties,
              ...userSourceProperties,
              ...utmProperties(),
              ...mobileWebAppTrackingProperties(),
              ...experimentsTrackingProperties,
              ...(event.properties ?? {}),
            },
          },
          apiConfig,
          keepAlive
        );
      }
      return;
    },
    [
      initialized,
      eventMap,
      currentBrand,
      userInfoContext?.state?.sessionInfo?.delegatedTo,
      userInfoContext?.state?.sessionInfo?.userInfo?.userState,
      userInfoContext?.state?.sessionInfo?.userScope?.isSignedIn,
      currency,
      locale,
      version,
      deviceProperties,
      pageProperties,
      userSourceProperties,
      experimentsTrackingProperties,
    ]
  );
};
