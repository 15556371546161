import { default as dayjs, Dayjs } from "dayjs";

export const URL_DATE_FORMAT = "YYYY-MM-DD";

export const toDate = (dateString?: string | Dayjs): Dayjs => dayjs(dateString);

export function formatDateForUrl(dateString: string | Dayjs): string;
export function formatDateForUrl(
  dateString?: string | Dayjs
): string | undefined;
export function formatDateForUrl(
  dateString?: string | Dayjs
): string | undefined {
  return dateString ? toDate(dateString).format(URL_DATE_FORMAT) : undefined;
}
