import { Box } from "@mui/material";
import { BoxProps } from "@mui/system";

const Text = (props) => {
  return (
    <svg
      viewBox="0 0 201 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Rewards">
        <path
          id="Vector"
          d="M188.941 36.6732C184.265 36.6732 179.481 35.1072 176.74 32.6232L180.18 26.6832C182.115 28.4112 186.254 30.1932 189.318 30.1932C191.79 30.1932 192.811 29.4912 192.811 28.3572C192.811 25.1172 177.707 28.4652 177.707 17.8812C177.707 13.2912 181.685 9.29517 188.834 9.29517C193.241 9.29517 196.896 10.6992 199.691 12.7512L196.52 18.6372C194.961 17.1252 192.005 15.7752 188.888 15.7752C186.953 15.7752 185.663 16.5312 185.663 17.5572C185.663 20.4192 200.713 17.4492 200.713 28.1412C200.713 33.1092 196.359 36.6732 188.941 36.6732Z"
          fill="#111111"
        />
        <path
          id="Vector_2"
          d="M174.223 36.0251H166V33.0011C163.85 35.4851 161.216 36.6731 158.313 36.6731C151.917 36.6731 146.864 31.8131 146.864 22.9571C146.864 14.4251 151.809 9.29508 158.313 9.29508C161.162 9.29508 163.957 10.4291 166 13.0211V0.00708008H174.223V36.0251ZM161.001 29.3291C162.775 29.3291 164.925 28.3571 166 26.9531V18.9611C164.925 17.5571 162.775 16.6391 161.001 16.6391C157.722 16.6391 155.303 19.0691 155.303 22.9571C155.303 26.8451 157.722 29.3291 161.001 29.3291Z"
          fill="#111111"
        />
        <path
          id="Vector_3"
          d="M137.996 36.025H129.718V9.94297H137.996V13.129C139.716 11.023 143.048 9.24097 146.112 9.24097V17.233C145.628 17.071 144.929 17.017 144.069 17.017C141.919 17.017 139.071 17.935 137.996 19.393V36.025Z"
          fill="#111111"
        />
        <path
          id="Vector_4"
          d="M125.053 36.0252H116.776V33.4332C115.163 35.3772 112.046 36.6732 108.713 36.6732C104.736 36.6732 99.7908 33.9192 99.7908 28.0872C99.7908 21.7692 104.736 19.6632 108.713 19.6632C112.153 19.6632 115.217 20.7972 116.776 22.7412V20.1492C116.776 17.8812 114.841 16.3152 111.508 16.3152C108.928 16.3152 106.348 17.3412 104.252 19.1232L101.188 13.3452C104.521 10.4832 108.982 9.29517 112.96 9.29517C119.195 9.29517 125.053 11.6172 125.053 19.7172V36.0252ZM112.153 31.2192C113.981 31.2192 115.916 30.5712 116.776 29.3292V27.0072C115.916 25.7652 113.981 25.1712 112.153 25.1712C110.003 25.1712 108.015 26.1432 108.015 28.1952C108.015 30.2472 110.003 31.2192 112.153 31.2192Z"
          fill="#111111"
        />
        <path
          id="Vector_5"
          d="M92.4211 36.0251H83.4985L78.6072 19.8791L73.7697 36.0251H64.8471L57.1608 9.94312H65.6533L69.8459 25.9271L74.9522 9.94312H82.316L87.4223 25.9271L91.5611 9.94312H100.107L92.4211 36.0251Z"
          fill="#111111"
        />
        <path
          id="Vector_6"
          d="M44.8599 36.6732C36.8511 36.6732 30.7235 31.4352 30.7235 22.9572C30.7235 15.3972 36.3673 9.29517 44.4837 9.29517C52.17 9.29517 57.76 14.9652 57.76 23.8212V25.7112H39.2699C39.8611 28.0332 42.0111 29.9772 45.7737 29.9772C47.6012 29.9772 50.6112 29.1672 52.1162 27.7632L55.7175 33.2172C53.1375 35.5392 48.8374 36.6732 44.8599 36.6732ZM49.8587 20.0412C49.6437 18.2052 48.2999 15.9912 44.4837 15.9912C40.8824 15.9912 39.4849 18.1512 39.1624 20.0412H49.8587Z"
          fill="#111111"
        />
        <path
          id="Vector_7"
          d="M30.6236 36.0251H20.0348L14.0147 23.9291H9.28467V36.0251H0.0395966V0.00708008H17.9922C25.9473 0.00708008 30.4623 5.29908 30.4623 11.9951C30.4623 18.2591 26.6998 21.6071 23.3135 22.7951L30.6236 36.0251ZM16.5947 15.8291C19.0673 15.8291 21.056 14.4791 21.056 11.9411C21.056 9.45708 19.0673 8.10708 16.5947 8.10708H9.28467V15.8291H16.5947Z"
          fill="#111111"
        />
      </g>
    </svg>
  );
};

const Bunny = (props) => {
  return (
    <svg
      viewBox="0 0 48 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4931_1623)">
        <circle cx="23.7758" cy="23.4509" r="23.2759" fill="#FA6866" />
        <g clipPath="url(#clip1_4931_1623)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.6851 14.7807C30.4504 13.5643 30.3163 12.2466 30.8527 12.2466C31.6573 12.2466 33.4007 13.1927 33.6689 17.0446C33.803 18.734 33.6018 20.5248 33.6018 20.5248C33.6354 20.7951 41.0446 25.5931 33.4342 27.3501C32.6631 27.519 35.9151 29.7829 36.3175 32.1481C36.6192 33.8375 35.5464 33.9051 34.7082 33.432C33.7695 32.8576 32.8307 31.3371 31.9255 30.2559C28.8747 26.573 20.4931 19.849 10.6029 28.4313C9.39598 29.4788 8.42373 25.9648 14.6931 21.0316C15.4642 20.4234 13.7544 21.1668 12.9498 20.0518C11.4411 17.9231 14.2237 15.8958 16.1012 15.8282C16.6041 15.8282 16.7382 16.5377 16.7382 18.0244C16.7382 18.6326 21.8677 13.9022 29.4781 21.3019C29.914 21.7412 30.3498 21.2344 30.3498 21.2344C30.2492 20.8965 29.4446 19.7139 28.4388 18.3285C26.6955 15.8958 26.6955 13.2602 27.6007 12.8886C28.0365 12.7196 28.9752 13.6995 29.344 14.3077C30.0816 15.5579 31.1209 19.2408 31.6573 20.4572L32.2608 20.2545C32.0596 19.0381 30.8862 15.9295 30.6851 14.7807Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4931_1623">
          <rect
            width="46.5517"
            height="46.5517"
            fill="white"
            transform="translate(0.499893 0.175049)"
          />
        </clipPath>
        <clipPath id="clip1_4931_1623">
          <rect
            width="27.1552"
            height="27.1552"
            fill="white"
            transform="translate(9.71318 9.38843)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const RewardsLogo = (props: BoxProps) => {
  return (
    <Box
      {...props}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: ({ tokens }) => `${tokens.spacing[150]}px`,
        flexShrink: 0,
        height: "46px",
        ...props.sx,
        "& svg": {
          height: "100%",
        },
      }}
    >
      <Bunny />
      <Text
        style={{
          height: "80%",
        }}
      />
    </Box>
  );
};
