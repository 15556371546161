import { ExperimentVariant } from "@hopper-b2b/types";
import { useExperiments } from "./useExperiments";

/**
 * @description Checks if an experiment is "on" or in the optionally passed in state
 * @param {string} expName
 * @param {string} [variant=ExperimentVariant.Available]
 * @param {boolean} [returnUndefined=false]
 * @return {boolean}
 */
export const useExperiment = (
  expName: string,
  variant: string = ExperimentVariant.Available,
  returnUndefined = false
): boolean | undefined => {
  const expVariant = useExperimentVariant(expName);
  if (returnUndefined && !expVariant) return undefined;

  const value = expVariant ?? ExperimentVariant.Control;

  return value === variant.toLowerCase();
};

export const useExperimentVariant = (expName: string): string | undefined => {
  const exps = useExperiments();
  const value = exps?.experiments?.[expName];

  return value?.toLowerCase();
};

export function useExperimentNotIn(
  experimentName: string,
  ...variants: string[]
): boolean {
  const exps = useExperiments();
  const expVariant = exps?.experiments?.[experimentName]?.toLowerCase();

  return (
    !!expVariant &&
    !variants.map((variant) => variant.toLowerCase()).includes(expVariant)
  );
}
