import { DateTime } from "../../common";

export interface AmenitiesAndUtas {
  amenities?: DullesAmenities;
  upas: DullesUpa[];
  utas: DullesUtas;
}

export interface DullesAmenities {
  seat: DullesSeat;
  wifi: DullesWifi;
  power: DullesPower;
  entertainment: DullesEntertainment;
  freshFood: DullesFreshFood;
  beverage: DullesBeverage;
  layout: DullesLayout;
  aircraft: DullesAircraft;
}

export interface DullesUta {
  headline: string;
  description: string;
  icon?: DullesIcon;
  cta?: DullesCta;
  assessment: string;
  category: string;
  bagLimits: DullesBagLimits;
}

export interface Fare {
  brandName: string;
  brandCodes: string[];
}

export interface Fee {
  currency: string;
  price: number;
}

export type PolicyAssessment =
  | PolicyAssessmentBenefit
  | PolicyAssessmentNeutral
  | PolicyAssessmentRestriction;

export enum PolicyAssessmentEnum {
  Benefit = "Benefit",
  Neutral = "Neutral",
  Restriction = "Restriction",
}

export interface PolicyAssessmentBenefit {
  PolicyAssessment: PolicyAssessmentEnum.Benefit;
}

export interface PolicyAssessmentNeutral {
  PolicyAssessment: PolicyAssessmentEnum.Neutral;
}

export interface PolicyAssessmentRestriction {
  PolicyAssessment: PolicyAssessmentEnum.Restriction;
}

export interface UtasPolicy {
  headline: string;
  description?: string;
  assessment: PolicyAssessment;
  fee?: Fee;
}

// note: this enum is created for handling the FTC logic in the FE; it's also used in tracking
export enum FtcType {
  FtcNoFees = "FTC_no_fees",
  FtcWithFees = "FTC_fees",
  NoFtc = "non_refundable",
}

export interface DullesUtas {
  fare?: Fare;
  utas: DullesUta[];
  changePolicy?: MultiPolicyV2;
  cancellationPolicy?: MultiPolicyV2;
}

export interface MultiPolicyV2 {
  afterDeparture?: UtasPolicy;
  anytime?: UtasPolicy;
  beforeDeparture?: UtasPolicy;
}

export interface DullesIcon {
  smallIconUrl: string;
  largeIconUrl: string;
}

export interface DullesCta {
  text: string;
  url: string;
}

export interface DullesFee {
  currency: string;
  price: number;
}

export interface DullesBagLimits {
  weightKg?: string;
  sizeLcm?: string;
}

export interface DullesUpa {
  headline: string;
  description: string;
  icon?: DullesIcon;
  cta?: DullesCta;
  category: string;
}

export interface DullesAmenityInfo {
  displayText?: string;
  quality: string;
  updatedAt?: DateTime;
}

export enum AmenityEnums {
  AmenityQuality,
  AmenityExists,
  SeatLegroom,
  SeatWidth,
  SeatFlatness,
}

export interface DullesAmenity {
  info: DullesAmenityInfo;
}

export interface DullesAmenityCanExist extends DullesAmenity {
  exists: string;
}

export interface DullesSeat extends DullesAmenity {
  info: DullesAmenityInfo;
  legroom: string;
  pitch: string;
  width: string;
  flatness: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DullesWifi extends DullesAmenityCanExist {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DullesPower extends DullesAmenityCanExist {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DullesEntertainment extends DullesAmenityCanExist {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DullesFreshFood extends DullesAmenityCanExist {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DullesBeverage extends DullesAmenityCanExist {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DullesLayout extends DullesAmenity {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DullesAircraft extends DullesAmenity {}

export enum AmenityQuality {
  Standard,
  Better,
  Na,
}

export enum SeatFlatness {
  Na,
  NotFlat,
  AngleFlat,
  FullFlat,
}

export enum SeatLegroom {
  Less,
  Standard,
  More,
  Na,
}

export enum SeatWidth {
  Standard,
  Wider,
  Na,
}

export enum AmenityExists {
  Yes,
  No,
  Na,
}

export enum WifiChance {
  Full,
  VeryGood,
  Good,
  Some,
  Na,
}

export enum AmenityCost {
  Free,
  Paid,
  Na,
}

export enum AmenityMixedCost {
  Free,
  FreeOrPaid,
  Paid,
  Na,
}

export enum PowerDistribution {
  Some,
  All,
  Na,
}

export enum CabinPressure {
  Enhanced,
  Normal,
  Unpressurized,
  Na,
}

export enum WindowSize {
  Smaller,
  Larger,
  Standard,
  Na,
}

export enum UtaAssessment {
  Neutral,
  Benefit,
  Restriction,
  Fee,
}

export enum UtaCategory {
  AdvanceChange,
  BoardingPriority,
  Cancellation,
  CarryOnAllowance,
  CheckInPriority,
  CheckedBagAllowance,
  LoungeAccess,
  SameDayChange,
  SeatSelection,
  UpgradeEligibility,
}

export enum UpaCategory {
  Brand,
  Promotion,
}

/*
  note: the following constants are values from the 'utas' object;
  ideally, they should be passed to the FE as case classes.
*/
export enum UtasPolicyCategory {
  AdvanceChange = "advance-change",
  Cancellation = "cancellation",
}

export enum UtasPolicyDescription {
  AdvanceChangeAllowedForFree = "Advance change allowed for free",
  AdvanceChangeAllowedForAFee = "Advance change allowed for a fee",
  AdvanceChangeNotAllowed = "Advance change not allowed",
}

export enum UtasPolicyHeadline {
  ForFree = "Free",
  ForAFee = "For a fee",
  AssignedForAFee = "Assigned or for a fee",
  NotAllowed = "Not Allowed",
  NonRefundable = "Non-refundable",
}
