import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import {
  FareSliceDetails,
  TAP_MISSED_CONNECTION_BANNER,
  TAP_SELF_CHECK_BAGGAGE_BANNER,
  TrackingEventEntryPoint,
  TripSegment,
  VirtualInterlineBannerClickedProperties,
} from "@hopper-b2b/types";
import {
  ActionButton,
  BannerSeverity,
  IconComponent,
  IconName,
  NotificationBanner,
  RemoteAirlineIcon,
  SelfTransferModal,
} from "@hopper-b2b/ui";
import { formatInterval, removeTimezoneRegex } from "@hopper-b2b/utilities";
import clsx from "clsx";
import dayjs from "dayjs";
import { ReactElement, useCallback, useState } from "react";
import styles from "./styles.module.scss";

import { DateTimeFormatStyle, formatDateTime } from "@hopper-i18n/formatter";
import { Box, Typography } from "@mui/material";
import { MissedConnectionGuaranteeModal } from "../MissedConnectionGuaranteeModal";
import { useTrackEvent } from "@hopper-b2b/tracking";

interface Classes {
  headerClassName: string;
}

interface BannerContent {
  reCheckBaggage: JSX.Element;
  mcpProtection: JSX.Element;
}

export interface IFlightVirtualInterlineDetailsSummary {
  className?: string;
  departureTime?: string;
  segments: TripSegment[];
  header?: JSX.Element;
  plusDays?: number;
  fareSlice?: FareSliceDetails;
  mobileLayout?: boolean;
  entryPoint?: TrackingEventEntryPoint;
  isOutgoing?: boolean;
  onChangeFlight?: () => void;
  classes?: Classes;
  bannerContent?: BannerContent;
}

export const FlightVirtualInterlineDetailsSummary = ({
  className,
  segments,
  departureTime,
  header,
  fareSlice,
  plusDays,
  mobileLayout,
  entryPoint,
  isOutgoing,
  classes,
  onChangeFlight = null,
  bannerContent,
}: IFlightVirtualInterlineDetailsSummary): ReactElement => {
  const { t, brand, language } = useI18nContext();
  const trackEvent = useTrackEvent();
  const [openModal, setOpenModal] = useState<
    "selfCheckLuggage" | "missedConnectionGuarantee" | false
  >(false);
  const renderTripSlice = useCallback(() => {
    return (
      <Box>
        {segments.map((segment: TripSegment, index: number) => {
          const hasDifferentOperatingAirline =
            segment.operatingAirline?.code !== segment.marketingAirline?.code;
          const isSelfTransferLayover =
            fareSlice?.fareDetails?.segments[index]?.isSelfTransferLayover;

          return (
            <Box
              key={`trip-segment-${segment.flightNumber}-${index}`}
              className={styles.tripSegmentBox}
            >
              {!mobileLayout && (
                <RemoteAirlineIcon
                  className={styles.airlineIcon}
                  airlineCode={segment.airlineCode}
                  altText={segment.airlineName}
                />
              )}

              <Box className={styles.flightTimeInfoWrapper}>
                <Box className={styles.timeInfoLine} />
                <Box className={styles.flightTimeInfoContainer}>
                  <Box className={styles.timeDetails}>
                    <IconComponent
                      className={styles.startCircle}
                      name={IconName.Ellipse}
                    />
                    <Typography
                      variant="body2"
                      className={styles.departureDetails}
                    >
                      <strong>
                        {dayjs(
                          removeTimezoneRegex(segment.departureTime)
                        ).format(brand?.timeFormat)}
                      </strong>{" "}
                      - {segment.originName} ({segment.originCode})
                    </Typography>
                  </Box>
                  <Typography variant="body1" className={styles.travelTime}>
                    {`${t("travelTime")} ${formatInterval(
                      dayjs(segment.arrivalTime).diff(
                        dayjs(segment.departureTime),
                        "minutes"
                      )
                    )}`}
                  </Typography>
                  <Box className={styles.timeDetails}>
                    <IconComponent
                      className={styles.endCircle}
                      name={IconName.Ellipse}
                    />
                    <Typography
                      variant="body2"
                      className={styles.arrivalDetails}
                    >
                      <strong>
                        {dayjs(removeTimezoneRegex(segment.arrivalTime)).format(
                          brand?.timeFormat
                        )}
                      </strong>{" "}
                      - {segment.destinationName} ({segment.destinationCode})
                    </Typography>
                  </Box>
                  <Box
                    className={clsx(styles.flightInfoDetails, {
                      [styles.mobileLayout]: mobileLayout,
                    })}
                  >
                    {mobileLayout && (
                      <RemoteAirlineIcon
                        className={styles.mobileAirlineIcon}
                        size="small"
                        airlineCode={segment.airlineCode}
                        altText={segment.airlineName}
                      />
                    )}
                    <Typography variant="body1">
                      {`${segment.airlineName} - ${segment.airlineCode}
                                    ${segment.flightNumber}`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {hasDifferentOperatingAirline && (
                <Typography
                  className={styles.operatingAirlineInfo}
                  variant="body1"
                >
                  {t("operatedBy", {
                    airline: segment.operatingAirline.name,
                  })}
                </Typography>
              )}
              {segment.stopoverDurationMinutes && (
                <>
                  <NotificationBanner
                    hideIcon
                    content={
                      <div className={styles.bannerContent}>
                        <IconComponent name={IconName.LayoverClock} />
                        <Typography
                          variant="body1"
                          className={styles.layoverLocationLabel}
                        >
                          <I18nMarkup
                            tKey="layoverWithLocationStrong"
                            replacements={{
                              duration: formatInterval(
                                segment.stopoverDurationMinutes || 0
                              ),
                              location: segment.destinationCode,
                            }}
                          />
                        </Typography>
                      </div>
                    }
                    className={clsx(
                      styles.stopoverDetails,
                      isSelfTransferLayover && styles.withBorderBottom
                    )}
                    severity={BannerSeverity.INFO}
                  />
                  {isSelfTransferLayover && (
                    <Box>
                      <NotificationBanner
                        hideIcon
                        content={
                          <div className={styles.bannerContent}>
                            <IconComponent name={IconName.BaggageCarryOn} />
                            <Typography variant="body2">
                              {bannerContent?.reCheckBaggage ?? (
                                <I18nMarkup tKey="reCheckBaggage" />
                              )}
                              <strong className={styles.learnMore}>{` ${t(
                                "learnMore"
                              )}`}</strong>
                            </Typography>
                          </div>
                        }
                        className={styles.selfCheckBaggageBanner}
                        severity={BannerSeverity.INFO}
                        onClick={() => {
                          setOpenModal("selfCheckLuggage");
                          trackEvent({
                            eventName: TAP_SELF_CHECK_BAGGAGE_BANNER,
                            properties: {
                              entry_point: entryPoint,
                              slice_direction: isOutgoing
                                ? "outbound"
                                : "return",
                            } as VirtualInterlineBannerClickedProperties,
                          });
                        }}
                      />
                      <NotificationBanner
                        hideIcon
                        content={
                          <div className={styles.bannerContent}>
                            <IconComponent
                              name={IconName.ShieldMissedConnection}
                            />
                            <Typography variant="body2">
                              {bannerContent?.mcpProtection ?? (
                                <I18nMarkup tKey="tripProtectionMcpBannerUpdated" />
                              )}
                              <strong
                                className={clsx(styles.learnMore, styles.trip)}
                              >{` ${t("learnMore")}`}</strong>
                            </Typography>
                          </div>
                        }
                        className={styles.missedConectionProtectionBanner}
                        severity={BannerSeverity.INFO}
                        onClick={() => {
                          setOpenModal("missedConnectionGuarantee");
                          trackEvent({
                            eventName: TAP_MISSED_CONNECTION_BANNER,
                            properties: {
                              entry_point: entryPoint,
                              slice_direction: isOutgoing
                                ? "outbound"
                                : "return",
                            } as VirtualInterlineBannerClickedProperties,
                          });
                        }}
                      />
                    </Box>
                  )}
                </>
              )}
            </Box>
          );
        })}
      </Box>
    );
  }, [
    brand?.timeFormat,
    fareSlice?.fareDetails?.segments,
    mobileLayout,
    setOpenModal,
    segments,
    t,
  ]);

  return (
    <Box
      className={clsx(styles.flightVirtualInterlineDetailsSummary, className)}
    >
      <Box className={styles.flightDetailsSubtitle}>
        {header && (
          <Box
            className={clsx(
              styles.flightDetailsHeaderContainer,
              "flight-details-header-container",
              classes?.headerClassName
            )}
          >
            {header}
          </Box>
        )}
        {Boolean(departureTime) && (
          <Typography
            variant="h5"
            className={styles.flightDepartureArrivalLabel}
          >
            <IconComponent
              name={IconName.PlaneTakeoff}
              className={styles.planeIcon}
              ariaLabel={t("flightDepartureIconAria")}
            />

            {t("departsOnWithDate", {
              date: formatDateTime(
                new Date(removeTimezoneRegex(departureTime)),
                language,
                DateTimeFormatStyle.Custom({
                  weekday: "long",
                  month: "short",
                  day: "numeric",
                })
              ),
            })}
          </Typography>
        )}
        {renderTripSlice()}
        <Typography variant="h5" className={styles.flightDepartureArrivalLabel}>
          <IconComponent
            name={IconName.PlaneLanding}
            ariaLabel={t("flightArrivalIconAria")}
            className={styles.planeIcon}
          />
          <I18nMarkup
            tKey="arrivesOnWithDate"
            className={clsx({ [styles.arrivalPlusDays]: Boolean(plusDays) })}
            replacements={{
              date: formatDateTime(
                new Date(
                  removeTimezoneRegex(segments[segments.length - 1].arrivalTime)
                ),
                language,
                DateTimeFormatStyle.Custom({
                  weekday: "long",
                  month: "short",
                  day: "numeric",
                })
              ),
            }}
          />
        </Typography>
        {onChangeFlight && (
          <Box>
            <ActionButton
              variant="outlined"
              color="primary"
              className={styles.changeFlightBtn}
              onClick={onChangeFlight}
              message={t("flightShopReview.changeFlight")}
            />
          </Box>
        )}
      </Box>
      <SelfTransferModal
        open={openModal === "selfCheckLuggage"}
        onClose={() => setOpenModal(false)}
      />
      <MissedConnectionGuaranteeModal
        open={openModal === "missedConnectionGuarantee"}
        onClose={() => setOpenModal(false)}
      />
    </Box>
  );
};

export default FlightVirtualInterlineDetailsSummary;
