import { FlightItinerarySlice } from "@b2bportal/air-booking-api";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCircle, faPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  FareSliceDetails,
  TrackingEventEntryPoint,
  TripSegment,
} from "@hopper-b2b/types";

import {
  formatInterval,
  removeTimezone,
  useDeviceTypes,
} from "@hopper-b2b/utilities";
import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import dayjs from "dayjs";
import { ReactElement, ReactNode, useCallback, useMemo } from "react";
import { ActionButton } from "@hopper-b2b/ui-core";
import { ScheduleChangeBadge } from "../ScheduleChangeBadge";
import { SliceToShow } from "../SliceSkchDiff";

import "./styles.scss";
import { RemoteAirlineIcon } from "../RemoteAirlineIcon";

/**
 * Flight Details Summary Card
 * @function FlightDetailsSummary
 *
 */
export interface IFlightDetailsSummaryProps {
  className?: string;
  departureTime: string;
  onClick?: () => void;
  planeInfo: string;
  fareClass: string;
  segments: TripSegment[];
  showTitle?: boolean;
  plusDays?: number;
  header?: JSX.Element;
  fareSlice?: FareSliceDetails;
  isMixedCabinClass?: boolean;
  tripSlice?: FlightItinerarySlice;
  onChange?: () => void;
  renderAirlineIconSection?: boolean;
  entryPoint?: TrackingEventEntryPoint;
  hasMajorScheduleChange?: boolean;
  hasMinorScheduleChange?: boolean;
  isOutgoing?: boolean;
  sliceToShow?: SliceToShow;
  skchSlice?: FlightItinerarySlice;
  totalDurationMinutes?: number;
}

const renderMixedCabinClass = (
  idx: number,
  fareClass: string,
  fareSlice?: FareSliceDetails,
  tripSlice?: FlightItinerarySlice
) => {
  const segment = fareSlice
    ? fareSlice.fareDetails.segments[idx]
    : tripSlice
    ? tripSlice.segments[idx]
    : undefined;
  if (segment) {
    if (segment.cabinClassName && segment.cabinClassName.includes("Economy")) {
      return segment.cabinClassName;
    } else {
      return fareClass;
    }
  } else {
    return fareClass;
  }
};

// TODO add parameters for localization
export const FlightDetailsSummary = ({
  className,
  planeInfo,
  fareClass,
  hasMajorScheduleChange,
  hasMinorScheduleChange,
  segments,
  departureTime,
  showTitle = true,
  plusDays,
  header,
  fareSlice,
  isMixedCabinClass,
  sliceToShow = SliceToShow.original,
  skchSlice,
  tripSlice,
  onChange,
  renderAirlineIconSection = true,
}: IFlightDetailsSummaryProps): ReactElement => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();

  const content = useMemo(() => {
    return {
      operatedBy: (segment: TripSegment) =>
        t("operatedBy", {
          airline: segment.operatingAirline.name,
        }),
      layoverWithLocation: (segment: TripSegment) =>
        t("layoverWithLocation", {
          duration: formatInterval(segment.stopoverDurationMinutes || 0),
          location: segment.destinationCode,
        }),
      flightNextDay: t("flightNextDay"),
    };
  }, [t]);

  const renderPlaneInfo = useCallback(
    (segment: TripSegment, index: number) => (
      <Typography variant="body1" className="flight-info">
        {`${segment.airlineName} / ${segment.airlineCode}
                  ${segment.flightNumber} / ${
          isMixedCabinClass
            ? (fareSlice || tripSlice) &&
              renderMixedCabinClass(index, fareClass, fareSlice, tripSlice)
            : fareClass
        }`}
        {planeInfo && ` / ${planeInfo}`}
      </Typography>
    ),
    [fareClass, fareSlice, isMixedCabinClass, planeInfo, tripSlice]
  );

  const getSegmentTimeDetails = (
    segment: TripSegment,
    scheduledTime,
    updatedTime,
    hasScheduleChange,
    isOutgoing
  ) => {
    const cityName = isOutgoing ? segment.originName : segment.destinationName;
    const cityCode = isOutgoing ? segment.originCode : segment.destinationCode;
    const fmtScheduledTime = scheduledTime.format("h:mm A");
    // start with original slice time
    let SliceTime: ReactNode = <span>{fmtScheduledTime}</span>;

    if (hasScheduleChange) {
      const fmtUpdatedTime = updatedTime?.format("h:mm A");

      switch (sliceToShow) {
        case SliceToShow.both:
          // overwrite to show old and new slice times
          SliceTime = (
            <>
              <s>{fmtScheduledTime}</s>
              <b>{fmtUpdatedTime}</b>
            </>
          );
          break;
        case SliceToShow.original:
          // overwrite to show only new slice time
          SliceTime = <s>{fmtScheduledTime}</s>;
          break;
        case SliceToShow.updated:
          // overwrite to show only new slice time
          SliceTime = <b>{fmtUpdatedTime}</b>;
          break;
      }
    }

    return (
      <>
        {SliceTime}
        <span>{` - ${cityName} (${cityCode})`}</span>
      </>
    );
  };

  const renderTripSlice = () => {
    return (
      <Box className="trip-slice">
        {segments.map((segment: TripSegment, index: number) => {
          let scheduledArrival, scheduledDeparture;
          let updatedArrival, updatedDeparture;
          const hasDifferentOperatingAirline =
            segment.operatingAirline?.code !== segment.marketingAirline?.code;
          const skchSegment = skchSlice?.segments[index];
          const hasScheduleChange =
            hasMajorScheduleChange || hasMinorScheduleChange;

          // use values from itinerary segment
          // comes from `getTripSegmentsFromItinerarySegments()`
          ({
            arrivalTime: scheduledArrival,
            departureTime: scheduledDeparture,
          } = segment);

          // use schedule change proposal times if available
          // set all 4 variables to show original times as strikedthrough
          if (skchSegment) {
            ({
              scheduledArrival: updatedArrival,
              scheduledDeparture: updatedDeparture,
            } = skchSegment);

            updatedArrival = dayjs(removeTimezone(updatedArrival));
            updatedDeparture = dayjs(removeTimezone(updatedDeparture));
          }

          scheduledArrival = dayjs(removeTimezone(scheduledArrival));
          scheduledDeparture = dayjs(removeTimezone(scheduledDeparture));

          return (
            <Box key={`trip-segment-${segment.flightNumber}-${index}`}>
              <Box className={`trip-segment-${segment.flightNumber}`}>
                <Box className="flight-time-info-wrapper">
                  <Box className="time-info-line"></Box>
                  <Box className={"flight-time-info-container"}>
                    <Box className="time-details">
                      <FontAwesomeIcon
                        className="start-circle"
                        icon={faCircle as IconProp}
                      />
                      <Typography className="departure-details" variant="h6">
                        {getSegmentTimeDetails(
                          segment,
                          scheduledDeparture,
                          updatedDeparture,
                          hasScheduleChange,
                          true
                        )}
                      </Typography>
                      <ScheduleChangeBadge
                        hasMajorScheduleChange={hasMajorScheduleChange}
                        hasMinorScheduleChange={hasMinorScheduleChange}
                      />
                    </Box>
                    <span className="trip-timing-line" />
                    <Typography variant="body1" className="travel-time">
                      {`${t("travelTime")}: ${
                        hasScheduleChange && skchSlice
                          ? formatInterval(
                              dayjs(skchSegment.updatedArrival).diff(
                                dayjs(skchSegment.updatedDeparture),
                                "minutes"
                              )
                            )
                          : formatInterval(
                              dayjs(segment.arrivalTime).diff(
                                dayjs(segment.departureTime),
                                "minutes"
                              )
                            )
                      }`}
                    </Typography>
                    <Box className="time-details">
                      <FontAwesomeIcon
                        className="end-circle"
                        icon={faCircle as IconProp}
                      />
                      <Typography className="arrival-details" variant="h6">
                        {getSegmentTimeDetails(
                          segment,
                          scheduledArrival,
                          updatedArrival,
                          hasScheduleChange,
                          false
                        )}
                      </Typography>
                    </Box>
                    {renderAirlineIconSection ? null : (
                      <Box className="flight-info-details no-icon">
                        {renderPlaneInfo(segment, index)}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
              {renderAirlineIconSection ? (
                <Box className="flight-info-details">
                  <Box className="airline-details">
                    <RemoteAirlineIcon
                      airlineCode={segment.airlineCode}
                      altText={segment.airlineName}
                    />
                  </Box>
                  {renderPlaneInfo(segment, index)}
                </Box>
              ) : null}
              {hasDifferentOperatingAirline && (
                <Typography className="operating-airline-info" variant="body1">
                  {content.operatedBy(segment)}
                </Typography>
              )}
              {segment.stopoverDurationMinutes && (
                <Typography variant="subtitle2" className="stopover-details">
                  {content.layoverWithLocation(segment)}
                </Typography>
              )}
            </Box>
          );
        })}

        {!!plusDays && (
          <div>
            <Typography variant="body2" className="plus-days">
              {content.flightNextDay}
            </Typography>
          </div>
        )}
      </Box>
    );
  };

  return (
    <Box
      className={clsx("flight-details-summary", className, {
        mobile: matchesMobile,
      })}
    >
      <Box className="flight-details-subtitle">
        {header && header}
        {showTitle && (
          <Typography variant="subtitle2">
            <>
              <FontAwesomeIcon icon={faPlane as IconProp} />
              {t("departingOnWithDate", {
                date: dayjs(removeTimezone(departureTime)).format("ddd, MMM D"),
              })}
            </>
          </Typography>
        )}
        {renderTripSlice()}
      </Box>
      {onChange ? (
        <Box>
          <ActionButton
            variant="outlined"
            color="primary"
            className="flight-details-change-cta"
            onClick={onChange}
            message={t("flightShopReview.changeFlight")}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default FlightDetailsSummary;
