import {
  ChatConversation,
  ChatMessage,
  SupportType,
} from "@b2bportal/chat-api";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { BottomSheetContentEnum } from "../types";

export type SupportChatState = {
  bottomSheetContent?: BottomSheetContentEnum;
  activeChatSession?: ChatConversation;
  activeSupportType?: SupportType;
  isMinimized: boolean;
  isFabVisible: boolean;
};

const initialState: SupportChatState = {
  bottomSheetContent: BottomSheetContentEnum.Closed,
  activeChatSession: undefined,
  activeSupportType: undefined,
  isMinimized: true,
  isFabVisible: false,
};

const supportChatSlice = createSlice({
  name: "supportChat",
  initialState,
  reducers: {
    /**
     * @description For starting a new chat session
     */
    startChatSession(
      state: SupportChatState,
      action: PayloadAction<ChatConversation>
    ) {
      state.activeChatSession = action.payload;
      state.isMinimized = false;
    },
    /**
     * @description For updating the chat session with a new message
     */
    updateChatSessionMessages(
      state: SupportChatState,
      action: PayloadAction<ChatMessage[]>
    ) {
      if (state.activeChatSession !== undefined) {
        state.activeChatSession.messages = action.payload;
      }
    },
    /**
     * @description For unmounting the entire ChatWindow component after the
     * chat session is ended
     */
    endChatSession(state: SupportChatState) {
      state.activeChatSession = undefined;
      state.activeSupportType = undefined;
    },
    /**
     * @description For minimizing the chat window while keeping the chat
     * session active
     */
    minimizeChatWindow(state: SupportChatState) {
      state.isMinimized = true;
    },
    /**
     * @description For toggling the chat window's visibility while
     * keeping the chat session active
     */
    toggleChatWindow(state: SupportChatState) {
      state.isMinimized = !state.isMinimized;
    },
    /**
     * @description For updating the bottom sheet content
     */
    setBottomSheetContent(
      state: SupportChatState,
      action: PayloadAction<BottomSheetContentEnum>
    ) {
      state.bottomSheetContent = action.payload;
    },
    /**
     * @description For showing or hiding the chat FAB
     */
    setChatFabVisible(state: SupportChatState, action: PayloadAction<boolean>) {
      state.isFabVisible = action.payload;
    },

    /**
     * @description For setting the active support type
     */
    setActiveSupportType(
      state: SupportChatState,
      action: PayloadAction<SupportType | undefined>
    ) {
      state.activeSupportType = action.payload;
    },
  },
});

const {
  actions: {
    startChatSession,
    updateChatSessionMessages,
    endChatSession,
    minimizeChatWindow,
    toggleChatWindow,
    setBottomSheetContent,
    setChatFabVisible,
    setActiveSupportType,
  },
  reducer: supportChatReducer,
} = supportChatSlice;

export {
  startChatSession,
  updateChatSessionMessages,
  endChatSession,
  minimizeChatWindow,
  supportChatReducer,
  toggleChatWindow,
  setBottomSheetContent,
  setChatFabVisible,
  setActiveSupportType,
};
