import {
  CreatePersonalOfferRequest,
  CreatePersonalOfferResponse,
  GetDealTilesRequest,
  GetDealTilesResponseResponse,
  GetRotatingBannersResponse,
  RotatingBanner,
  TrackImpressionRequest,
  TrackImpressionResponseResponse,
} from "@b2bportal/ads-api";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { createPersonalOffer, fetchRotatingBanners } from "../api";
import { getDealTiles } from "../api/getDealTiles";
import { trackClick } from "../api/trackClick";
import { trackImpression } from "../api/trackImpression";

export interface IHomePageState {
  rotatingBanners: Array<RotatingBanner>;
}

export const initialState: IHomePageState = {
  rotatingBanners: [],
};

export const adsApi = createApi({
  reducerPath: "adsApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  endpoints: (builder) => ({
    fetchRotatingBanners: builder.query<GetRotatingBannersResponse, void>({
      queryFn: async (_arg, api) => {
        const data = await fetchRotatingBanners(api.signal);
        return { data };
      },
    }),
    createPersonalOffer: builder.mutation<
      CreatePersonalOfferResponse,
      CreatePersonalOfferRequest
    >({
      queryFn: async (arg, api) => {
        const data = await createPersonalOffer(arg, api.signal);
        return { data };
      },
    }),
    getDealTiles: builder.query<
      GetDealTilesResponseResponse,
      GetDealTilesRequest
    >({
      queryFn: async (arg) => {
        const data = await getDealTiles(arg);
        return { data };
      },
    }),
    trackImpression: builder.mutation<
      TrackImpressionResponseResponse,
      TrackImpressionRequest
    >({
      queryFn: async (arg, api) => {
        const data = await trackImpression(arg, api.signal);
        return { data };
      },
    }),
    trackClick: builder.mutation<
      TrackImpressionResponseResponse,
      TrackImpressionRequest
    >({
      queryFn: async (arg, api) => {
        const data = await trackClick(arg, api.signal);
        return { data };
      },
    }),
  }),
});

export const {
  useFetchRotatingBannersQuery,
  useCreatePersonalOfferMutation,
  useGetDealTilesQuery,
  useTrackImpressionMutation,
  useTrackClickMutation,
} = adsApi;
