import { v4 as uuidv4 } from "uuid";

// Return the value that is used to populate `browsingSessionId` in hopper-web-bff.
// It is used by the fraud team to track the user.
export const getBrowserSessionId = () => {
  const sessionIdKey = "sessionId";
  const sessionId = sessionStorage.getItem(sessionIdKey) || uuidv4();
  sessionStorage.setItem(sessionIdKey, sessionId);

  return sessionId;
};
