import "./styles.scss";
import ReactPhoneInput from "react-phone-number-input";
import {
  FormHelperText,
  Stack,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { forwardRef, useRef } from "react";

type PhoneInputProps = {
  onChange: (value?: string) => void;
  onBlur?: (value?: string) => void;
  placeholder?: string;
  label?: string;
  defaultValue?: string;
  value?: string;
  error?: boolean;
  errorHelper?: string;
  hideErrorHelperWhenEmpty?: boolean;
  type?: string;
  fullWidth?: boolean;
  width?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  countryCode?: string;
  helperText?: string;
  inputRef?: TextFieldProps["inputRef"];
};
/**
 *  PhoneInput accepts an E.164 defaultValue, which is a string that includes the international '+' prefix,
 *  countryCallingCode, and national number (e.g. "+12133734253"). It then parses the country code and phone number from it
 *  and sets them as the initial state. The countryCallingCode and phone number input states are managed separately,
 *  and then passes the combined value to the parent component using the onChange and onBlue props.
 *
 */
export const PhoneInput = ({
  onChange,
  errorHelper,
  disabled,
  label,
  value,
  helperText,
}: PhoneInputProps) => {
  const parentRef = useRef(null);

  return (
    <>
      <Stack direction={"column"} alignItems={"stretch"} ref={parentRef}>
        <ReactPhoneInput
          disabled={disabled}
          international={true}
          defaultCountry={"US"}
          value={value}
          label={label}
          onChange={onChange}
          inputComponent={CustomPhoneInput}
          errorHelper={errorHelper}
        />
      </Stack>
      <FormHelperText
        variant="outlined"
        error={!!errorHelper}
        sx={{
          position: "relative",
          fontSize: ({ tokens }) => tokens.typography.fontSize[2],
        }}
      >
        {errorHelper ? errorHelper : helperText}
      </FormHelperText>
    </>
  );
};

export const CustomPhoneInput = forwardRef(
  (props: TextFieldProps & PhoneInputProps, ref) => {
    return (
      <TextField
        {...props}
        type="tel"
        error={!!props.errorHelper}
        variant={"filled"}
        inputRef={ref}
        inputProps={{
          maxLength: 16,
        }}
        InputProps={{
          disableUnderline: true,
        }}
        sx={{
          "& .MuiFilledInput-root": {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          },
        }}
      />
    );
  }
);
