const HOPPER_BRAND = "hopper";
const ATHOTEL_BRAND = "athotel";
const ATHOTEL_STAGING_HOST =
  "booking-ui-git-hopper-redirect-at-hotel.vercel.app";

export const currentBrand = (): string => {
  if (process.env["NEXT_PUBLIC_BRAND"])
    return process.env["NEXT_PUBLIC_BRAND"] ?? HOPPER_BRAND;
  if (import.meta.env["VITE_BRAND"])
    return import.meta.env["VITE_BRAND"] ?? HOPPER_BRAND;
  try {
    if (
      window.location.hostname.includes(ATHOTEL_BRAND) ||
      window.location.hostname.includes(ATHOTEL_STAGING_HOST)
    ) {
      return ATHOTEL_BRAND;
    }
    return HOPPER_BRAND;
  } catch (e) {
    return HOPPER_BRAND;
  }
};

const ATTRIBUTION_PARAM_KEY = "attribution";
const ATTRIBUTION_COOKIE_NAME = "Hopper-Attribution";

export function getBrandAttribution() {
  const params = new URLSearchParams(window.location.search);
  return params.get(ATTRIBUTION_PARAM_KEY);
}

export function detectBrandAttribution() {
  const attribution = getBrandAttribution();
  if (attribution) {
    document.cookie = `${ATTRIBUTION_COOKIE_NAME}=${attribution}; path=/`;
  }
}

export const isAtHotelBrand = () => currentBrand() === ATHOTEL_BRAND;
export const isHopperBrand = () => currentBrand() === HOPPER_BRAND;
export const isBrandedSite = () => !isHopperBrand();

export const getAtHotelBrandButtonStyling = ({ filled }: { filled: boolean }) =>
  isAtHotelBrand()
    ? { style: filled ? { background: "#17803C" } : { color: "#17803C" } }
    : {};
