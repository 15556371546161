import { Currency } from "./";

export interface BrandNames {
  clientName: ClientName;
  contactSupport: ContactSupportConfig;
  portalName: string;
  merchantPayments: string;
  currency: {
    locale: string;
    code: Currency;
    symbol?: string;
    highInflationCurrency?: boolean;
    symbolOverride?: { [code: string]: string };
  };
  urls: {
    termsAndConditions: string;
    privacyPolicy: string;
    updateBalance?: string;
    notifications?: string;
    disruptionTerms?: string;
    priceFreezeTerms?: string;
    cfarTerms?: string;
    chfarTerms?: string;
    missedConnectionTerms?: string;
    helpCenterBaseUrl?: string;
  };
  /**
   * @description Used to give preference when gathering traveler information
   */
  preferredCountryCode: string;
  /**
   * @description Used to auto-populate contact info form
   */
  preferredAreaCode: string;
  home: {
    title: string;
    subtitle: string;
    modal?: {
      title: string;
      subtitle: string;
      list: string[];
    };
  };
  loading?: {
    flightSearch: {
      subtitle: string;
    };
  };
  supportedLanguages?: Array<{
    key: string;
    name: string;
    label: string;
  }>;
  calendarDayFormat?: CalendarDayLetterFormat;
  calendarMonthFormat?: string;
  calendarDateLabelFormat?: string;
  timeFormat?: string;
  useImperialUnits?: boolean;
  flightShopCardType?: FlightShopCardType;
  adaChat?: {
    answerIds: {
      delayOrMissedConnection: string;
    };
  };
  lodgingCardPriceRounding?: number;
}

export interface Branding {
  default?: string;
  en?: BrandNames;
  es?: BrandNames;
  pt?: BrandNames;
  fr?: BrandNames;
  [key: string]: BrandNames | string | undefined;
}

export interface ContactSupportConfig {
  /**
   * @description Formatted international Customer Support number
   */
  displayIntlSupportNumber?: string;
  /**
   * @description Formatted Customer Support number
   */
  displaySupportNumber: string;
  /**
   * @description Raw international Customer Support number
   */
  intlSupportNumber?: string;
  phoneAvailability?: PhoneSupportAvailability;
  /**
   * @description Raw Customer Support number
   */
  supportNumber: string;
}

export interface PhoneSupportAvailability {
  air?: PhoneSupportVerticalConfig;
  general?: PhoneSupportVerticalConfig;
  ground?: PhoneSupportVerticalConfig;
  hotel?: PhoneSupportVerticalConfig;
  homes?: PhoneSupportVerticalConfig;
}

export interface PhoneSupportVerticalConfig {
  /**
   * @description The start hour in UTC. Current hour must be **greater than or equal to (>=)** this number
   */
  serviceStartUtc: number;
  /**
   * @description The end hour in UTC. Current hour must be **less than (<)** this number
   */
  serviceEndUtc: number;
}

export enum FlightShopCardType {
  MINI = "mini",
  REGULAR = "regular",
}

export enum ClientName {
  HOPPER = "hopper",
}

export enum CalendarDayLetterFormat {
  SINGLE = "single",
  DOUBLE = "double",
}
