"use client";

import { Claim, FlashSale } from "@b2bportal/hopper-flash-sale-api";
import { useExperiment } from "@hopper-b2b/experiments";
import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react";
import {
  useClaimOfferMutation,
  useFetchPublishedFlashSaleQuery,
  useFetchUserSaleContextQuery,
  useSubscribeToSaleMutation,
} from "./reducer";

dayjs.extend(isBetween);

export type IFlashSaleContextProps = {
  isPresale?: boolean;
  isSale?: boolean;
  targetDate?: Dayjs;
  sale?: FlashSale | undefined;
  claims: Array<Claim>;
  subscribedToSaleAt?: string;
  subscribeToSale?: () => void;
  claimOffer?: (offerId: string) => void;
};

export interface IFlashSaleContextProvider {
  flashSaleContext: IFlashSaleContextProps;
}

export const defaultFlashSaleContext: IFlashSaleContextProps = {
  isPresale: false,
  isSale: false,
  targetDate: dayjs(),
  sale: undefined,
  claims: [],
  subscribedToSaleAt: undefined,
  subscribeToSale: () => void 0,
  claimOffer: () => void 0,
};

export const FlashSaleContext = createContext<IFlashSaleContextProps>(
  defaultFlashSaleContext
);

export const useFlashSaleContext = () => {
  const ctx = useContext(FlashSaleContext);
  if (ctx === undefined)
    throw new Error(`must be used within a FlashSaleContextProvider`);
  return { ...ctx };
};

export interface FlashSaleProviderProps {
  isLoggedIn?: boolean;
  country?: string;
}

export const FlashSaleProvider = ({
  children,
  flashSaleContext,
  isLoggedIn,
  country,
}: PropsWithChildren<IFlashSaleContextProvider> & FlashSaleProviderProps) => {
  const isForcedPresale = useExperiment("FlashSalePreview", "presale");
  const isForcedSale = useExperiment("FlashSalePreview", "sale");
  const isRegionUS = useExperiment("FlashSaleRegionGating-US");
  const isRegionCA = useExperiment("FlashSaleRegionGating-CA");
  const isRegionAvailable = useMemo(
    () => country === "US" || country === "CA" || isRegionUS || isRegionCA,
    [country, isRegionCA, isRegionUS]
  );
  const { data: { sale } = flashSaleContext } = useFetchPublishedFlashSaleQuery(
    undefined,
    {
      skip: !isLoggedIn,
    }
  );
  const { data: { claims, subscribedToSaleAt } = flashSaleContext } =
    useFetchUserSaleContextQuery(
      {
        saleId: sale?.id.value ?? "",
      },
      {
        skip: !isLoggedIn || !sale?.id.value,
      }
    );

  const [subscribe] = useSubscribeToSaleMutation();
  const [claim] = useClaimOfferMutation();
  const isSale = useMemo(
    () =>
      (isForcedSale ||
        !!(
          sale &&
          dayjs().isBetween(
            dayjs(sale.activeTimeline.start),
            dayjs(sale.activeTimeline.end)
          )
        )) &&
      !isForcedPresale &&
      isRegionAvailable,
    [isForcedSale, sale, isForcedPresale, isRegionAvailable]
  );
  const isPresale = useMemo(
    () =>
      (isForcedPresale ||
        !!(
          sale &&
          dayjs().isBetween(
            dayjs(sale.activeTimeline.presaleStartDate),
            dayjs(sale.activeTimeline.start)
          )
        )) &&
      !isForcedSale &&
      isRegionAvailable,
    [isForcedPresale, sale, isForcedSale, isRegionAvailable]
  );
  const targetDate = useMemo(
    () =>
      isPresale
        ? dayjs(sale?.activeTimeline.start)
        : dayjs(sale?.activeTimeline.end),
    [isPresale, sale]
  );

  const subscribeToSale = useCallback(() => {
    sale &&
      subscribe({
        saleId: sale.id,
      });
  }, [sale, subscribe]);

  const claimOffer = useCallback(
    (offerId: string) => {
      sale &&
        claim({
          saleId: sale.id,
          offerId: { value: offerId },
        });
    },
    [claim, sale]
  );
  return (
    <FlashSaleContext.Provider
      value={{
        ...flashSaleContext,
        isPresale,
        isSale,
        targetDate,
        sale,
        claims,
        subscribedToSaleAt,
        subscribeToSale,
        claimOffer,
      }}
    >
      {children}
    </FlashSaleContext.Provider>
  );
};
