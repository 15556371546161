import { Components, typographyClasses } from "@mui/material";
import { bp } from "../breakpoints";
import { tokens } from "../tokens";
import { typography } from "../typography";
import { transitions } from "../transitions";

export const MuiButton: Components["MuiButton"] = {
  defaultProps: {
    variant: "filled",
    size: "medium",
    disableRipple: true,
    disableFocusRipple: true,
    disableTouchRipple: true,
  },
  styleOverrides: {
    root: {
      textTransform: "none",
      gap: tokens.spacing[100],
      "& .MuiButton-endIcon": {
        marginLeft: 0,
      },
    },
  },
  variants: [
    {
      props: { variant: "filled" },
      style: {
        color: tokens.primary.contrast,
        background: tokens.surface.primaryResting,
        "&:hover": {
          background: tokens.surface.primaryHover,
        },
        "&:active": {
          background: tokens.surface.primaryPressed,
        },
        "&:focus-visible": {
          boxShadow: `0px 0px 0px 2px ${tokens.primary.contrast}, 0px 0px 0px 4px ${tokens.primary.main}`,
        },
        "&:disabled": {
          color: tokens.text.disabled,
          background: tokens.component.control.surfaceDisabled,
        },
      },
    },
    {
      props: { variant: "filled", color: "athotel" },
      style: {
        color: tokens.primary.contrast,
        background: tokens.surface.atHotelBranded,
        "&:hover": {
          background: tokens.surface.atHotelBrandedHover,
        },
        "&:active": {
          background: tokens.surface.atHotelBrandedPressed,
        },
        "&:focus-visible": {
          boxShadow: `0px 0px 0px 2px ${tokens.primary.contrast}, 0px 0px 0px 4px ${tokens.surface.atHotelBranded}`,
        },
        "&:disabled": {
          color: tokens.text.disabled,
          background: tokens.component.control.surfaceDisabled,
        },
      },
    },
    {
      props: { variant: "filled", color: "onPrimary" },
      style: {
        color: tokens.primary.main,
        background: tokens.surface.main,
        "&:hover": {
          color: tokens.primary.main,
          background: tokens.surface.mainHover,
        },
        "&:active": {
          color: tokens.primary.main,
          background: tokens.component.control.surfacePressed,
        },
        "&:focus-visible": {
          color: tokens.primary.main,
          boxShadow: `0px 0px 0px 2px ${tokens.primary.main}, 0px 0px 0px 4px rgba(255, 255, 255, 0.40)`,
        },
        "&:disabled": {
          color: tokens.text.disabled,
          background: tokens.component.control.surfaceDisabled,
        },
      },
    },
    {
      props: { variant: "filled", color: "onPhoto" },
      style: {
        color: tokens.primary.contrast,
        background: tokens.surface.primaryResting,
        "&:hover": {
          background: tokens.surface.primaryHover,
        },
        "&:active": {
          background: tokens.surface.primaryPressed,
        },
        "&:focus-visible": {
          boxShadow: `0px 0px 0px 2px ${tokens.primary.main}, 0px 0px 0px 4px rgba(255, 255, 255, 0.40)`,
        },
        "&:disabled": {
          color: tokens.text.disabled,
          background: tokens.component.control.surfaceDisabled,
        },
      },
    },
    {
      props: { variant: "outlined" },
      style: {
        color: tokens.primary.main,
        border: `1px solid ${tokens.primary.main}`,
        "&:hover": {
          background: tokens.surface.primaryTintHover,
        },
        "&:active": {
          background: tokens.surface.primaryTintPressed,
        },
        "&:focus-visible": {
          boxShadow: `0px 0px 0px 2px ${tokens.primary.contrast}, 0px 0px 0px 4px ${tokens.primary.main}`,
        },
        "&:disabled": {
          color: tokens.text.disabled,
        },
      },
    },
    {
      props: { variant: "outlined", color: "onPrimary" },
      style: {
        color: tokens.primary.contrast,
        border: `1px solid ${tokens.primary.contrast}`,
        "&:hover": {
          color: tokens.primary.contrast,
          border: `1px solid ${tokens.primary.contrast}`,
          background: tokens.surface.primaryHover,
        },
        "&:active": {
          color: tokens.primary.contrast,
          border: `1px solid ${tokens.primary.contrast}`,
          background: tokens.surface.primaryPressed,
        },
        "&:focus-visible": {
          color: tokens.primary.contrast,
          border: `1px solid ${tokens.primary.contrast}`,
          boxShadow: `0px 0px 0px 2px ${tokens.primary.main}, 0px 0px 0px 4px rgba(255, 255, 255, 0.40)`,
        },
        "&:disabled": {
          color: tokens.text.disabled,
        },
      },
    },
    {
      props: { variant: "outlined", color: "onPhoto" },
      style: {
        color: tokens.primary.contrast,
        border: `1px solid ${tokens.primary.contrast}`,
        "&:hover": {
          border: `1px solid ${tokens.primary.contrast}`,
          background: tokens.surface.stateOverlay.hover,
        },
        "&:active": {
          border: `1px solid ${tokens.primary.contrast}`,
          background: tokens.surface.stateOverlay.pressed,
        },
        "&:focus-visible": {
          border: `1px solid ${tokens.primary.contrast}`,
          boxShadow: `0px 0px 0px 2px ${tokens.primary.main}, 0px 0px 0px 4px rgba(255, 255, 255, 0.40)`,
        },
        "&:disabled": {
          color: tokens.text.disabled,
        },
      },
    },
    {
      props: { variant: "raised" },
      style: {
        color: tokens.text.primary,
        background: tokens.surface.raisedSurface.resting,
        "&:hover": {
          background: tokens.surface.raisedSurface.hover,
        },
        "&:active": {
          background: tokens.surface.raisedSurface.pressed,
        },
        "&:focus-visible": {
          boxShadow: `0px 0px 0px 2px ${tokens.primary.contrast}, 0px 0px 0px 4px ${tokens.primary.main}`,
        },
        "&:disabled": {
          color: tokens.text.disabled,
          background: tokens.component.control.surfaceDisabled,
        },
      },
    },
    {
      props: { variant: "raised", color: "onPrimary" },
      style: {
        color: tokens.primary.contrast,
        background: tokens.surface.raisedSurface.restingOverlay,
        "&:hover": {
          background: tokens.surface.raisedSurface.hoverOverlay,
        },
        "&:active": {
          background: tokens.surface.raisedSurface.pressedOverlay,
        },
        "&:focus-visible": {
          boxShadow: `0px 0px 0px 2px ${tokens.primary.main}, 0px 0px 0px 4px rgba(255, 255, 255, 0.40)`,
        },
        "&:disabled": {
          color: tokens.text.disabled,
          background: tokens.component.control.surfaceDisabled,
        },
      },
    },
    {
      props: { variant: "raised", color: "onPhoto" },
      style: {
        color: tokens.primary.contrast,
        background: tokens.surface.raisedSurface.restingOverlay,
        "&:hover": {
          background: tokens.surface.raisedSurface.hoverOverlay,
        },
        "&:active": {
          background: tokens.surface.raisedSurface.pressedOverlay,
        },
        "&:focus-visible": {
          boxShadow: `0px 0px 0px 2px ${tokens.primary.main}, 0px 0px 0px 4px rgba(255, 255, 255, 0.40)`,
        },
        "&:disabled": {
          color: tokens.text.disabled,
          background: tokens.component.control.surfaceDisabled,
        },
      },
    },
    {
      props: { variant: "text" },
      style: {
        color: tokens.primary.main,
        "&:hover": {
          background: tokens.component.control.surfaceHover,
        },
        "&:active": {
          background: tokens.component.control.surfacePressed,
        },
        "&:focus-visible": {
          boxShadow: `0px 0px 0px 2px ${tokens.primary.contrast}, 0px 0px 0px 4px rgba(0, 116, 218, 0.40);`,
        },
        "&:disabled": {
          color: tokens.text.disabled,
        },
      },
    },
    {
      props: { variant: "text", color: "onPrimary" },
      style: {
        color: tokens.primary.contrast,
        "&:hover": {
          color: tokens.primary.contrast,
          background: tokens.surface.primaryHover,
        },
        "&:active": {
          color: tokens.primary.contrast,
          background: tokens.surface.primaryPressed,
        },
        "&:focus-visible": {
          color: tokens.primary.contrast,
          boxShadow: `0px 0px 0px 2px ${tokens.primary.main}, 0px 0px 0px 4px rgba(255, 255, 255, 0.40)`,
        },
        "&:disabled": {
          color: tokens.text.disabled,
        },
      },
    },
    {
      props: { variant: "text", color: "onPhoto" },
      style: {
        color: tokens.primary.contrast,
        background: tokens.surface.stateOverlay.resting,
        "&:hover": {
          background: tokens.surface.stateOverlay.hover,
        },
        "&:active": {
          background: tokens.surface.stateOverlay.pressed,
        },
        "&:focus-visible": {
          boxShadow: `0px 0px 0px 2px ${tokens.primary.main}, 0px 0px 0px 4px rgba(255, 255, 255, 0.40)`,
        },
        "&:disabled": {
          color: tokens.text.disabled,
          background: tokens.surface.stateOverlay.resting,
        },
      },
    },
    {
      props: { variant: "secondary" },
      style: {
        color: tokens.text.primary,
        "&:hover": {
          background: tokens.component.control.surfaceHover,
        },
        "&:active": {
          background: tokens.component.control.surfacePressed,
        },
        "&:focus-visible": {
          boxShadow: `0px 0px 0px 2px ${tokens.primary.contrast}, 0px 0px 0px 4px rgba(0, 116, 218, 0.40);`,
        },
        "&:disabled": {
          color: tokens.text.disabled,
        },
      },
    },
    {
      props: { variant: "secondary", color: "onPrimary" },
      style: {
        color: tokens.primary.contrast,
        "&:hover": {
          color: tokens.primary.contrast,
          background: tokens.surface.primaryHover,
        },
        "&:active": {
          color: tokens.primary.contrast,
          background: tokens.surface.primaryPressed,
        },
        "&:focus-visible": {
          color: tokens.primary.contrast,
          boxShadow: `0px 0px 0px 2px ${tokens.primary.main}, 0px 0px 0px 4px rgba(255, 255, 255, 0.40)`,
        },
        "&:disabled": {
          color: tokens.text.disabled,
        },
      },
    },
    {
      props: { variant: "secondary", color: "onPhoto" },
      style: {
        color: tokens.primary.contrast,
        "&:hover": {
          background: tokens.surface.stateOverlay.hover,
        },
        "&:active": {
          background: tokens.surface.stateOverlay.pressed,
        },
        "&:focus-visible": {
          boxShadow: `0px 0px 0px 2px ${tokens.primary.main}, 0px 0px 0px 4px rgba(255, 255, 255, 0.40)`,
        },
        "&:disabled": {
          color: tokens.text.disabled,
        },
      },
    },
    {
      props: { variant: "ghost" },
      style: {
        "&:hover": {
          background: "transparent",
        },
        "&:active": {
          background: "transparent",
        },
        "&:focus-visible": {
          boxShadow: `0px 0px 0px 2px ${tokens.primary.contrast}, 0px 0px 0px 4px rgba(0, 116, 218, 0.40);`,
        },
      },
    },
    {
      props: { variant: "tdt" },
      style: {
        color: "#181579",
        background:
          "linear-gradient(90deg, var(--button-background-1) 0%, var(--button-background-2) 100%)",
        transition: `
          --button-background-1 ${transitions.duration.short}ms ${transitions.easing.easeInOut},
          --button-background-2 ${transitions.duration.short}ms ${transitions.easing.easeInOut},
          box-shadow ${transitions.duration.short}ms ${transitions.easing.easeInOut},
          border-color ${transitions.duration.short}ms ${transitions.easing.easeInOut},
          color ${transitions.duration.short}ms ${transitions.easing.easeInOut}
        `,
        "&:hover": {
          "--button-background-1": "#F6D252",
          "--button-background-2": "#F5B831",
        },
        "&:active": {
          "--button-background-1": "#EECC50",
          "--button-background-2": "#EDB230",
        },
        "&:focus-visible": {
          boxShadow: `0px 0px 0px 2px ${tokens.primary.contrast}, 0px 0px 0px 4px #FED955`,
        },
        "&:disabled": {
          "--button-background-1": "#FECF85",
          "--button-background-2": "#FDCB66",
        },
      },
    },
    {
      props: { variant: "tdtSuccess" },
      style: {
        "--button-background-1": tokens.colors.green[10],
        "--button-background-2": tokens.colors.green[10],
        color: tokens.colors.green[60],
        background:
          "linear-gradient(90deg, var(--button-background-1) 0%, var(--button-background-2) 100%)",
        transition: `
          --button-background-1 ${transitions.duration.short}ms ${transitions.easing.easeInOut},
          --button-background-2 ${transitions.duration.short}ms ${transitions.easing.easeInOut},
          box-shadow ${transitions.duration.short}ms ${transitions.easing.easeInOut},
          border-color ${transitions.duration.short}ms ${transitions.easing.easeInOut},
          color ${transitions.duration.short}ms ${transitions.easing.easeInOut}
        `,
        "&:disabled": {
          color: tokens.colors.green[60],
          "--button-background-1": tokens.colors.green[10],
          "--button-background-2": tokens.colors.green[10],
        },
      },
    },
    {
      props: { size: "large" },
      style: {
        padding: `${tokens.spacing[100]}px ${tokens.spacing[400]}px`,
        borderRadius: tokens.cornerRadius.radiusXl,
        minHeight: "52px",
        ...typography.buttonLarge,
      },
    },
    {
      props: { size: "medium" },
      style: {
        padding: `${tokens.spacing[100]}px ${tokens.spacing[300]}px`,
        borderRadius: tokens.cornerRadius.radiusLg,
        minHeight: "44px",
        ...typography.buttonMedium,
      },
    },
    {
      props: { size: "small" },
      style: {
        padding: `${tokens.spacing[50]}px ${tokens.spacing[200]}px`,
        borderRadius: tokens.cornerRadius.radiusMd,
        minHeight: "34px",
        ...typography.buttonSmall,
      },
    },
    {
      props: { size: "extraSmall" },
      style: {
        padding: `${tokens.spacing[50]}px ${tokens.spacing[150]}px`,
        borderRadius: "1000px",
        minHeight: "30px",
        ...typography.buttonSmall,
      },
    },
    {
      props: { size: "link" },
      style: {
        padding: 0,
        color: tokens.text.link,
        ...typography.buttonLink,
      },
    },
    {
      props: { size: "search" },
      style: {
        [bp.down("tablet")]: {
          padding: `${tokens.spacing[100]}px ${tokens.spacing[400]}px`,
          borderRadius: tokens.cornerRadius.radiusXl,
          minHeight: "52px",
          ...typography.buttonLarge,
        },
        [bp.up("tablet")]: {
          padding: `${tokens.spacing[100]}px ${tokens.spacing[250]}px`,
          borderRadius: "1000px",
          minHeight: "50px",
          maxHeight: "50px",
          minWidth: "50px",
          maxWidth: "100%",
          justifyContent: "flex-start",
          overflow: "hidden",
          "& svg": {
            width: "24px",
            height: "24px",
            flexShrink: 0,
          },
          "& :not(svg):not(svg *)": {
            opacity: 1,
            transition: `opacity ${transitions.duration.shorter}ms ${transitions.easing.easeOut}`,
          },
          transition: `all ${transitions.duration.shorter}ms ${transitions.easing.easeOut}`,
          transitionProperty:
            "max-width, padding, opacity, transform, background-color",
          ...typography.buttonLarge,
        },
      },
    },
    {
      props: { size: "searchCollapsed" },
      style: {
        [bp.down("tablet")]: {
          padding: `${tokens.spacing[100]}px ${tokens.spacing[400]}px`,
          borderRadius: tokens.cornerRadius.radiusXl,
          minHeight: "52px",
          ...typography.buttonLarge,
        },
        [bp.up("tablet")]: {
          padding: `${tokens.spacing[100]}px ${tokens.spacing[100]}px`,
          borderRadius: "1000px",
          minHeight: "50px",
          maxHeight: "50px",
          minWidth: "50px",
          maxWidth: "50px",
          justifyContent: "flex-start",
          overflow: "hidden",
          "& svg": {
            width: "24px",
            height: "24px",
            flexShrink: 0,
            transform: `translateX(5px)`,
            transition: `transform ${transitions.duration.shorter}ms ${transitions.easing.easeOut}`,
          },
          "& :not(svg):not(svg *)": {
            opacity: 0,
            transition: `opacity ${transitions.duration.shorter}ms ${transitions.easing.easeOut}`,
          },
          [`& .${typographyClasses.root}`]: {
            visibility: "hidden",
          },
          transition: `all ${transitions.duration.shorter}ms ${transitions.easing.easeOut}`,
          transitionProperty:
            "max-width, padding, opacity, transform, background-color",
          ...typography.buttonLarge,
        },
      },
    },
    {
      props: { size: "searchCollapsedMini" },
      style: {
        padding: `${tokens.spacing[100]}px ${tokens.spacing[100]}px`,
        borderRadius: "1000px",
        minHeight: "36px",
        maxHeight: "36px",
        minWidth: "36px",
        maxWidth: "36px",
        justifyContent: "flex-start",
        overflow: "hidden",
        "& svg": {
          width: "18px",
          height: "18px",
          flexShrink: 0,
          transform: `translateX(1px)`,
          transition: `transform ${transitions.duration.shorter}ms ${transitions.easing.easeOut}`,
        },
        "& :not(svg):not(svg *)": {
          opacity: 0,
          transition: `opacity ${transitions.duration.shorter}ms ${transitions.easing.easeOut}`,
        },
        [`& .${typographyClasses.root}`]: {
          visibility: "hidden",
        },
        transition: `all ${transitions.duration.shorter}ms ${transitions.easing.easeOut}`,
        transitionProperty:
          "max-width, padding, opacity, transform, background-color",
        ...typography.buttonLarge,
      },
    },
    {
      props: { size: "day" },
      style: {
        padding: `${tokens.spacing[150]}px ${tokens.spacing[100]}px`,
        borderRadius: tokens.cornerRadius.radiusMd,
        minHeight: "40px",
        minWidth: "40px",
        maxHeight: "40px",
        maxWidth: "40px",
        ...typography.labelMedium,
      },
    },
    {
      props: { size: "valueStepper" },
      style: {
        padding: `${tokens.spacing[100]}px ${tokens.spacing[100]}px`,
        minHeight: "32px",
        minWidth: "32px",
        borderRadius: "100px",
        border: `1px solid ${tokens.surface.borderDivider}`,
        backgroundColor: tokens.component.control.surfaceResting,
      },
    },
    {
      props: { size: "menu" },
      style: {
        justifyContent: "flex-start",
        paddingTop: `${tokens.spacing[250]}px`,
        paddingBottom: `${tokens.spacing[250]}px`,
        [bp.up("mobile")]: {
          paddingLeft: `${tokens.spacing[tokens.container.padding.mobile]}px`,
          paddingRight: `${tokens.spacing[tokens.container.padding.mobile]}px`,
        },
        [bp.up("tablet")]: {
          paddingLeft: `${tokens.spacing[tokens.container.padding.tablet]}px`,
          paddingRight: `${tokens.spacing[tokens.container.padding.tablet]}px`,
        },
        [bp.up("desktop")]: {
          paddingLeft: `${tokens.spacing[tokens.container.padding.desktop]}px`,
          paddingRight: `${tokens.spacing[tokens.container.padding.desktop]}px`,
        },
        ...typography.buttonMedium,
      },
    },
    {
      props: { size: "ghost" },
      style: {
        padding: 0,
        gap: 0,
        justifyContent: "stretch",
        alignItems: "stretch",
        minWidth: 0,
        borderRadius: 0,
      },
    },
    {
      props: { size: "large", variant: "secondary" },
      style: {
        paddingLeft: `${tokens.spacing[250]}px`,
        paddingRight: `${tokens.spacing[250]}px`,
      },
    },
    {
      props: { size: "medium", variant: "secondary" },
      style: {
        paddingLeft: `${tokens.spacing[200]}px`,
        paddingRight: `${tokens.spacing[200]}px`,
      },
    },
    {
      props: { size: "small", variant: "secondary" },
      style: {
        paddingLeft: `${tokens.spacing[150]}px`,
        paddingRight: `${tokens.spacing[150]}px`,
      },
    },
  ],
};

export const MuiButtonOnPrimary: Components["MuiButton"] = {
  ...MuiButton,
  defaultProps: {
    ...MuiButton.defaultProps,
    color: "onPrimary",
  },
};

export const MuiButtonOnPhoto: Components["MuiButton"] = {
  ...MuiButton,
  defaultProps: {
    ...MuiButton.defaultProps,
    color: "onPhoto",
  },
};
