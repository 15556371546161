export const getScreen: () => string | undefined = () => {
  if (typeof window === "undefined") {
    return undefined;
  }

  const { pathname } = window.location;

  switch (true) {
    case pathname === "/":
    case ["en", "es", "fr"].some((lang) => pathname === `/${lang}`):
      return "home_screen";

    case pathname === "/hotels":
      return "hotels_home_screen";

    case pathname === "/flights":
      return "flights_home_screen";

    case pathname === "/deals":
      return "deals_page";

    case pathname === "/hotels/webapp":
      return "hotels_webapp_search";

    case pathname.includes("hotels/availability"):
    case pathname.includes("stays/availability"):
      return "lodging_list";

    case pathname.includes("hotels/shop"):
      return "hotels_details_page";

    case pathname.includes("/l/"):
      return "landing_page";

    default:
      return "unknown";
  }
};
