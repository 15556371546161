import {
  LocationQuery,
  LocationResponse,
  Suggestion,
  airShoppingApi,
} from "@b2bportal/air-shopping-api";
import { IApiConfig, NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import { axiosInstance } from "../../AxiosInterceptor";
import { trackEvent } from "../../tracking/trackEvent";

export const fetchLocationAutocomplete = async (
  body: LocationQuery,
  apiConfig?: IApiConfig
): Promise<LocationResponse> => {
  try {
    const res = await airShoppingApi(
      axiosInstance
    ).apiV0AutocompleteLocationPost(body);
    return res.data;
  } catch (e) {
    trackEvent(
      {
        eventName: NETWORK_CALL_FAILED,
        properties: {
          url: window.location.pathname,
        },
      },
      apiConfig
    );
    throw e;
  }
};

export const fetchLocationAutocompleteSingle = async (
  body: LocationQuery,
  apiConfig?: IApiConfig
): Promise<Suggestion | undefined> => {
  try {
    const res = await airShoppingApi(
      axiosInstance
    ).apiV0AutocompleteLocationPost(body);
    return res.data?.categories?.[0]?.results?.[0];
  } catch (e) {
    trackEvent(
      {
        eventName: NETWORK_CALL_FAILED,
        properties: {
          url: window.location.pathname,
        },
      },
      apiConfig
    );
    throw e;
  }
};
