import {
  CfarOfferPropertiesWithPerPax,
  ChfarOfferProperties,
} from "../../fintech";
import { ItineraryEnum } from "../../trips-module";
import { Dealness } from "./shop-summary";

// COMMON EVENTS

export const ADD_CONTACT_INFO = "add_contact_info";
export interface AddContactInfoProperties {
  product_type: ProductType;
}

export const ADD_TRAVELER = "add_traveler";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AddTravelerProperties {}

export const SELECT_TRAVELERS = "select_travelers";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SelectTravelersProperties {}

export const SELECT_REWARDS = "select_rewards";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SelectRewardsProperties {}

export const ADD_PAYMENT = "add_payment";
export interface AddPaymentProperties {
  success: boolean;
}

export const SELECT_PAYMENT = "select_payment";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SelectPaymentProperties {}

// AIR EVENTS

export const AIR_ENTRY = "air_entry";
export interface AirEntryProperties {
  first_launch: boolean;
  origin: string;
  destination: string;
  origin_country_code: string;
  destination_country_code: string;
  departure_date: string;
  return_date?: string;
  pax_total: number;
  number_of_active_watches: number;
  trip_type: "round_trip" | "one_way";
  rewards_accounts?: string;
  advanced: number;
  los: number;
}

export const VIEWED_FORECAST = "viewed_forecast";
export const VIEWED_FLIGHT_LIST = "viewed_flight_list";
export interface ViewedForecastProperties extends AirEntryProperties {
  slice_direction?: "outbound" | "return";
  flights_shown?: number;
  vi_flights_shown?: number;
  hopper_vi_flights_shown?: number;
  includes_hopper_vi?: boolean;
  account_type_selected?: string;
  recommendation: "buy" | "wait" | "null";
  dealness: Dealness | string;
  fares_shown_outbound?: number;
  fares_shown_return?: number;
}

export const TAPPED_FILTERS = "tapped_filters";
export const APPLIED_FILTER = "applied_filter";
export interface AppliedFilterProperties {
  filter_type:
    | "price"
    | "stops"
    | "airline"
    | "time"
    | "airport"
    | "flight number";
}

export const VIEWED_SLICE = "viewed_slice";
export interface ViewedSliceProperties extends ViewedForecastProperties {
  outboundSlice_brand_fareClass?: string;
  returnSlice_brand_fareClass?: string;
  outbound_vi_layovers?: number;
  return_vi_layovers?: number;
  outbound_duration_minutes?: number;
  return_duration_minutes?: number;
}

export const VIEWED_SLICE_FARE_RESTRICTIONS = "viewed_slice_fare_restrictions";

export interface ViewedSliceFareRestrictionsProperties {
  outbound_vi_layovers: number;
  return_vi_layovers: number;
  display_fare_restrictions: string;
  flights_shown: number;
  flights_shown_return: number;
  slice_direction: "outbound" | "return";
  outbound_duration_minutes: number;
  return_duration_minutes: number;
  outboundSlice_brand_fareClass: string;
  returnSlice_brand_fareClass: string;
}

export const CONFIRMED_SLICE = "confirmed_slice";

export interface ConfirmedSliceProperties {
  outbound_vi_layovers: number;
  return_vi_layovers: number;
  selected_fare: string;
  slice_direction: "outbound" | "return";
}

export const TAP_SELF_CHECK_BAGGAGE_BANNER = "tap_self_check_baggage_banner";
export const TAP_MISSED_CONNECTION_BANNER = "tap_missed_connection_banner";

export interface VirtualInterlineBannerClickedProperties {
  entry_point: string;
  slice_direction: "outbound" | "return";
}

export enum TrackingEventEntryPoint {
  Outbound = "outbound",
  Return = "return",
  Details = "itinerary_details",
  MetasearchLanding = "metasearch_landing",
  ContactInformation = "contact_information",
  TraverlerInformation = "traverler_information",
  Bags = "bags",
  Payment = "payment",
  TripInsurance = "trip_insurance",
  BookReview = "book_and_review",
  Confirmation = "booking_confirmation",
}

export const SELECTED_FLIGHT = "selected_flight";
export interface SelectedSliceProperties extends ViewedForecastProperties {
  fare_class: string;
  total_stops: number;
}

export const VIEWED_TRIP_SUMMARY = "viewed_trip_summary";

export const VIEWED_METASEARCH_LANDING = "viewed_metasearch_landing";

export interface ViewedTripSummaryProperties extends SelectedSliceProperties {
  is_multi_ticket: boolean;
}

export const VIEWED_FLIGHT_DETAILS = "viewed_flight_details";
export const VIEWED_FARE_RESTRICTIONS_BREAKDOWN =
  "viewed_fare_restrictions_breakdown";

export interface ViewedFlightDetailsProperties {
  outbound_vi_layovers: number;
  return_vi_layovers: number;
  entry_point: string;
  slice_direction_viewed: "outbound" | "return";
}

export const ADD_FREQUENT_FLYER = "add_frequent_flyer";
export interface AddFrequentFlyerProperties {
  frequent_flyer_program: string;
}

export const ADD_PASSPORT = "add_passport";
export interface AddPassportProperties {
  success: boolean;
}

export const REVIEW_FLIGHT_DETAILS = "review_flight_details";
export interface ReviewFlightDetailsProperties
  extends ViewedTripSummaryProperties {
  stops: number;
  provider: string;
  advance: string;
  carrier: string;
}

export const ADDED_WATCH = "added_watch";
export const WATCH_ENTRY = "watch_entry";
export const APPLIED_FREQUENT_FLYER = "applied_frequent_flyer";
export const SKIPPED_SEAT_SELECTION = "skipped_seats_selection";
export const REQUESTED_SEATMAP = "requested_seatmap";
export const VIEWED_SEATMAP = "viewed_seatmap";
export const CHOSE_FROM_SEATMAP = "chose_from_seatmap";
export const SEARCH_PASSENGER_COUNT_NOT_REACHED =
  "search_passenger_count_not_reached";

// HOTEL EVENTS
export const HOTEL_ENTRY = "hotel_entry";
export interface HotelEntryProperties {
  first_launch: boolean;
  market: string;
  country: string;
  number_of_properties: number;
  hotel_advance: string;
  rewards_accounts?: string;
}

export const VIEWED_HOTEL_LIST = "viewed_hotel_list";
export interface ViewedHotelListProperties extends HotelEntryProperties {
  account_type_selected: string;
  unavailable_hotels: number;
}

export const VIEWED_UNAVAILABLE_HOTEL_LIST = "viewed_unavailable_hotel_list";

export type ViewedUnavailableHotelListProperties = ViewedHotelListProperties;

export const SELECTED_UNAVAILABLE_HOTEL_FROM_LIST =
  "selected_unavailable_hotel_from_list";

export type SelectedUnavailableHotelFromList = ViewedHotelListProperties;

export const APPLIED_HOTEL_FILTER = "applied_hotel_filter";
export interface AppliedHotelFilterProperties {
  filter_type: "stars" | "price" | "hotel_name" | "amenities";
}

export const SELECTED_HOTEL_FROM_LIST = "selected_hotel_from_list";
export type SelectedHotelFromListProperties = ViewedHotelListProperties;

export const SELECTED_HOTEL_FROM_MAP = "selected_hotel_from_map";
export type SelectedHotelFromMapProperties = ViewedHotelListProperties;

export const VIEWED_HOTEL_DETAILS = "viewed_hotel_details";
export interface ViewedHotelDetailsProperties
  extends ViewedHotelListProperties {
  account_type_selected: string;
  rooms_shown: number;
}

export const ADD_DRIVERS_LICENSE = "add_drivers_license";

export const SELECT_HOTEL_ROOM = "select_hotel_room";
export interface SelectHotelRoomProperties
  extends ViewedHotelDetailsProperties {
  room_type: string;
  account_type_selected: string;
}

export const REVIEW_DETAILS_HOTEL_CHECKOUT = "review_details_hotel_checkout";
export interface ReviewDetailsHotelCheckoutProperties
  extends ViewedHotelListProperties {
  lodging_name: string;
  market: string;
  chain: string;
  brand: string;
  length_of_stay: number;
  sell_price_excluding_taxes_and_fees_usd: number;
  owed_now_excluding_taxes_and_fees: number;
  owed_now_taxes_and_fees: number;
  owed_at_checkin_taxes_and_fees: number;
  pay_later_excluding_taxes_and_fees: number;
  pay_later_taxes_and_fees: number;
  pay_later_fees: number;
  total_discount: number;
  refundability: "non_refundable" | "free_cancellation";
  supply_revenue_usd: number;
  check_in_date: string;
  check_out_date: string;
  supplier: string;
  hotel_advance: string;
  provider_type: "direct" | "retail" | "wholesale";
  adr: string;
}

export const COMPLETE_BUY_HOTEL = "complete_buy_hotel";
export interface CompleteBuyHotelProperties
  extends ReviewDetailsHotelCheckoutProperties {
  success: boolean;
  total_rewards_amount_usd: number;
  total_card_amount_usd: number;
  card_product_used: string;
  rewards_product_used: string;
  rewards_currency: string; // ie. “Miles”, “Cash”, “Points”
  agent_booking_fee_amount_usd: number;
  booking_session_id: string;
  reservation_id: string;
}

// CARS EVENTS
export const CARS_ENTRY = "cars_entry";
export interface CarsEntryProperties {
  first_launch: boolean;
  market: string;
  country: string;
  airport: string;
  pick_up_date: string;
  pick_up_hour: number;
  drop_off_date: string;
  drop_off_hour: number;
  search_age: number;
  same_pickUp_dropOff: boolean;
  cars_advance: string;
  rewards_accounts?: string;
}

export const VIEWED_CARS_LIST = "viewed_cars_list";
export interface ViewedCarsListProperties extends CarsEntryProperties {
  account_type_selected: string;
  number_of_results: number;
  cheapest_price: number;
  cheapest_provider: string;
  pick_up_place_country: string;
  pick_up_place_city: string;
  pick_up_place_id: string;
  drop_off_place_country: string;
  drop_off_place_city: string;
  drop_off_place_id: string;
}

export const APPLIED_CARS_FILTER = "applied_cars_filter";
export interface AppliedCarsFilterProperties {
  filter_type:
    | "car_type"
    | "price"
    | "rental_company"
    | "specifications"
    | "cancellation_policy";
}

export const SELECTED_CAR_FROM_LIST = "selected_car_from_list";
export interface SelectedCarFromListProperties
  extends ViewedCarsListProperties {
  vehicle_type: string;
  total_price: number;
  free_cancellation: boolean;
  unlimited_mileage: boolean;
  automatic: boolean;
  supplier: string;
  vendor: string;
  non_refundable: boolean;
  conditional_cancellation: boolean;
  conditional_cancellation_after_booking: boolean;
}

export const VIEWED_CAR_DETAILS = "viewed_car_details";
export interface ViewedCarDetailsProperties
  extends SelectedCarFromListProperties {
  account_type_selected: string;
  fuel_policy: string;
}

export const REVIEWED_CAR_DETAILS_CHECKOUT = "reviewed_car_details_checkout";
export interface ReviewedCarDetailsCheckoutProperties
  extends ViewedCarDetailsProperties {
  driver_age: number;
  pay_now: number;
  pay_later: number;
  estimated_commission: number;
}

export const COMPLETE_BUY_CARS = "complete_buy_car";
export interface CompleteBuyCarProperties
  extends ReviewedCarDetailsCheckoutProperties {
  success: boolean;
  total_rewards_amount_usd: number;
  total_card_amount_usd: number;
  card_product_used: string;
  rewards_product_used: string;
  rewards_currency: string;
  agent_booking_fee_amount_usd: number;
  booking_session_id: string;
  ground_booking_id: string;
}

// MY TRIPS EVENTS
export const VIEWED_MY_TRIPS = "viewed_my_trips";
export const VIEWED_CONTACT_MODAL = "viewed_contact_modal";
export interface MyTripsProperties {
  agent_locator: string;
  agent_locator_provider: string;
  days_until_departure: string;
  booking_date: string;
  has_skch?: string;
  skch_severity?: string;
}
export interface ViewedContactModalProperties extends MyTripsProperties {
  contact_reason:
    | "cancel_flight"
    | "change_flight"
    | "change_hotel"
    | "cancel_hotel"
    | "change_car"
    | "cancel_car"
    | "general_contact";
  contact_provider_air?: string;
  contact_provider_hotel?: string;
  contact_provider_ground?: string;
}
export const SELECT_WATCHED_FLIGHT = "select_watched_flight";
export const STOPPED_WATCH = "stopped_watch";
export interface StoppedWatchProperties {
  success: boolean;
}
export const VIEWED_MAJOR_SCHEDULE_CHANGE = "viewed_major_schedule_change";
export type ViewedMajorScheduleChangeProperties = MyTripsProperties;
export const USER_ACTION_SCHEDULE_CHANGE_SUCCESS =
  "user_action_schedule_change_success";
export interface UserActionScheduleChangeSuccessProperties {
  action: string;
  user_action: string;
  severity: string;
  system_locator_provider: string;
  agent_locator_provider: string;
  agent_locator: string;
  system_locator: string;
  days_until_departure: string;
  connection_time_valid: string;
  is_continuous: string;
  has_reprotection: string;
  provider: string;
  pcc: string;
  booking_date: string;
  itinerary_id: string;
  validating_carrier: string;
}
export const SCHEDULE_CHANGE_DECISION = "schedule_change_decision";
export interface ScheduleChangeDecisionProperties extends MyTripsProperties {
  decision_outcome: "accept" | "deny";
}
export const AUTO_ACCEPT = "auto_accept";

// ONBOARDING EVENTS

export const VIEWED_ONBOARDING = "viewed_onboarding";
export const COMPLETED_ONBOARDING = "completed_onboarding";
export const SKIPPED_ONBOARDING = "skipped_onboarding";

// FTUX

export const PRICE_DROP_VIEWED = "price_drop_viewed";
export interface PriceDropViewedProperties {
  page:
    | "watched_trips"
    | "forecast"
    | "flight_list"
    | "trip_summary"
    | "flight_book"
    | "payment_summary"
    | "trip_review"
    | "flight_confirmation";
  price_drop_offer_duration: number;
  price_drop_offer_max_cap: number;
  price_drop_offer_min_cap: number;
}

export const VIEWED_PRICE_DROP_DETAILS = "viewed_price_drop_details";
export interface ViewedPriceDropDetailsProperties {
  page:
    | "watched_trips"
    | "forecast"
    | "flight_list"
    | "trip_summary"
    | "flight_book"
    | "mobile"
    | "trip_review"
    | "flight-confirmation";
}

export const PRICE_DROP_ADDED = "price_drop_added";
export interface PriceDropAddedProperties {
  price_drop_offer_duration: number;
  price_drop_offer_max_cap: number;
  price_drop_offer_min_cap: number;
}

export const PRICE_DROP_REFUND_RECEIVED = "price_drop_refund_received";
export interface PriceDropRefundReceivedProperties {
  success: boolean;
  drop_amount: number;
}

// GENERAL EVENTS & FAILURES

export interface RuntimeProperties {
  runtime: number;
  provider?: string;
  carrier?: string;
}

export type LandingScreen =
  | "cars_list"
  | "cars_search"
  | "exchange"
  | "flight_search"
  | "flight_shop"
  | "ftux"
  | "hotel_list"
  | "hotel_search"
  | "my_trips"
  | "travel_wallet"
  | "home_page"
  | "metasearch";

export const LAUNCHED_APPLICATION = "launched_application";
export interface LaunchedApplicationProperties {
  landing_screen: LandingScreen;
  url: string;
  rewards_accounts?: string;
  experiments?: string[];
}

export const LOGGED_IN = "logged_in";
export interface LoggedInProperties {
  runtime: number;
}

export const NETWORK_CALL_FAILED = "network_call_failed";
export const MODAL_ALERT = "modal_alert";
export const MODAL_ALERT_CHOICE = "modal_alert_choice";

export const VIEWED_TAKEOVER = "viewed_takeover";
export const TAPPED_TAKEOVER_CHOICE = "tapped_takeover_choice";

export enum ModalCategoryType {
  FEATURE = "feature",
  TROUBLE = "trouble",
}

export enum ModalScreens {
  CARS_AVAILABILITY = "cars availability",
  CARS_CHECKOUT = "cars checkout",
  FLIGHTS_CHECKOUT = "flights checkout",
  HOTELS_AVAILABILITY = "hotels availability",
  HOTELS_SHOP = "hotels details",
  HOTELS_CHECKOUT = "hotels checkout",
}

export enum ModalButtonType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

export enum ProductType {
  FLIGHTS = "flights",
  LODGING = "lodging",
  CARS = "cars",
  PRICE_FREEZE = "price_freeze",
  DISRUPTION = "disruption",
  HOMES = "homes",
}

export interface ModalAlertProperties {
  type: string;
  category?: ModalCategoryType;
  screen?: ModalScreens;
  primary_button?: string;
  secondary_button?: string;
  product_type?: ProductType;
}

export interface ModalAlertChoiceProperties extends ModalAlertProperties {
  button_choice: ModalButtonType;
  button?: string;
}

export const VIEWED_FLIGHT_SHOP_LOADING = "viewed_flight_shop_loading";
export const VIEWED_FLIGHT_BOOK_PROCESSING = "viewed_flight_book_processing";
export const VIEWED_HOTEL_AVAILABILITY_LOADING =
  "viewed_hotel_availability_loading";
export const VIEWED_HOTEL_SHOP_LOADING = "viewed_hotel_shop_loading";
export const VIEWED_HOTEL_BOOK_PROCESSING = "viewed_hotel_book_processing";
export const VIEWED_CAR_AVAILABILITY_LOADING =
  "viewed_car_availability_loading";
export const VIEWED_CAR_SHOP_LOADING = "viewed_car_shop_loading";
export const VIEWED_CAR_BOOK_PROCESSING = "viewed_car_book_processing";
export const NO_AVAILABILITY_AIR = "no_availability_air";
export const NO_AVAILABILITY_HOTELS = "no_availability_hotels";
export const NO_AVAILABILITY_CARS = "no_availability_cars";
export const CHARGE_BOOKING_AGENT_FEE = "charge_booking_agent_fee";
export const PERFORMED_FLIGHT_SEARCH = "performed_flight_search";

export enum SelfServeEvents {
  ClickCancel = "self_serve_click_cancel_button",
  ClickSupport = "self_serve_click_support",
  ConfirmCancelation = "self_serve_confirm_cancelation",
  RedirectToAirline = "self_serve_redirect_to_airline",
  ResponseFailure = "self_serve_cancel_response_failure",
  ResponseSuccess = "self_serve_cancel_response_success",
  ViewedCancelModal = "self_serve_viewed_cancel_modal",
  ViewedChangeModal = "self_serve_viewed_change_modal",
  ViewedExchangeModal = "self_serve_viewed_exchange_modal",
  ViewedExchangeLanding = "self_serve_exchange_landingpage",
  ViewedExchangeShopPage = "self_serve_exchange_shoppage",
  ViewedExchangeReviewPage = "self_serve_exchange_reviewpage",
  ViewedExchangeConfirmPage = "self_serve_exchange_confirmpage",
  FTCExchangeViewedNoResults = "self_serve_ftcexchange_viewed_no_results",
  ExchangeNotEligible = "self_serve_exchange_not_eligible",
  ExchangeViewedNoResults = "self_serve_exchange_viewed_no_results",
  ExchangeSubmitSuccess = "self_serve_exchange_submitsuccess",
  ExchangeSubmitFailure = "self_serve_exchange_submitfailure",
  ViewedFTCExchangeLanding = "self_serve_ftcexchange_landingpage",
  ViewedFTCExchangeShopPage = "self_serve_ftcexchange_shoppage",
  ViewedFTCExchangeReviewPage = "self_serve_ftcexchange_reviewpage",
  ViewedFTCExchangeConfirmPage = "self_serve_ftcexchange_confirmpage",
  FTCExchangeNotEligible = "self_serve_ftcexchange_not_eligible",
  FTCExchangeSubmitSuccess = "self_serve_ftcexchange_submitsuccess",
  FTCExchangeSubmitFailure = "self_serve_ftcexchange_submitfailure",
  RebookingFeeWaived = "cfar_ftcexchange_rebookfeewaived",
}

export const VIEWED_RESEND_EMAIL = "viewed_resend_email";
export const CONFIRM_RESEND_EMAIL = "confirm_resend_email";

export interface ResendEmailProperties {
  screen: ItineraryEnum;
}
export interface ChargeBookingAgentFeeProperties {
  agent_booking_fee_amount_usd: number;
  success: boolean;
}
export const BOOKING_FEE_WAIVED = "booking_fee_waived";
export interface BookingFeeWaivedProperties {
  reason: string;
}

export const CLP_MODAL_VIEW = "CLP_modal_view";

export interface CLPModalViewProperties {
  origin: string;
  destination: string;
  origin_country_code: string;
  destination_country_code: string;
  departure_date: string;
  return_date: string;
  trip_type: "round_trip" | "one_way";
  fare_class:
    | "BasicEconomy"
    | "Economy"
    | "Business"
    | "PremiumEconomy"
    | "First";
}

export const CLP_CLICK = "CLP_click";

export enum CLPClickEnum {
  CHOOSE_ANOTHER_FLIGHT = "Choose another flight",
  CONTINUE = "Continue",
  EXIT = "Exit",
}

export interface CLPClickProperties {
  selected_button: CLPClickEnum;
}

export enum ChangeTripTypeScreenEnum {
  LOCATION_PICKER = "location_picker",
  LOCATION_MODAL = "location_modal",
  CALENDAR = "calendar",
}

export const CHANGE_FLIGHT = "change_flight";

export const EDIT_CONTACT = "edit_contact";

export interface ChooseTravelerProperties {
  origin: string;
  destination: string;
  origin_country_code: string;
  destination_country_code: string;
  departure_date: string;
  return_date?: string;
  trip_type: "round_trip" | "one_way";
}

export const VIEW_PAYMENT = "view_payment";
export interface ViewPaymentProperties extends ChooseTravelerProperties {
  adult_price_total_usd: number;
  base_total_usd: number;
  price_per_pax_usd: number;
  subtotal_usd: number;
  total_price_usd: number;
  charged_PQ_currency?: string;
  total_price: number;
  pax_total: number;
  selected_pax_total: number;
  total_stops: number;
}

export const CHOOSE_PAYMENT = "choose_payment";
export interface ChoosePaymentProperties
  extends ChooseTravelerProperties,
    ViewPaymentProperties {
  seat_infant_price_total_usd: number;
  selected_pax_children: number;
  selected_pax_lap_infants: number;
}

export enum CompleteBuyAirCfarChoices {
  ATTACHED = "1",
  DECLINED = "0",
  NOT_OFFERED = "none",
}

export enum CompleteBuyAirChfarChoices {
  ATTACHED = "1",
  DECLINED = "0",
  NOT_OFFERED = "none",
}

export const COMPLETE_BUY_AIR = "complete_buy_air";
export interface CompleteBuyAirProperties
  extends ChooseTravelerProperties,
    Partial<CfarOfferPropertiesWithPerPax>,
    Partial<ChfarOfferProperties> {
  booking_session_id: string;
  carrier: string;
  failure_reason?: string;
  is_agent_session: boolean;
  pax_total: number;
  success: boolean;
  rewards_currency?: string;
  total_rewards_amount_usd?: number;
  provider?: string;
  advance: string;
  fare_class?: string;
  is_multi_ticket: boolean;
  cfar_choice?: CompleteBuyAirCfarChoices;
  chfar_choice?: CompleteBuyAirChfarChoices;
  delay_protection_choice?: 0 | 1;
  missed_connection_choice?: 0 | 1;
  charged_delay_protection_total_usd?: number;
  charged_missed_connection_total_usd?: number;
  total_card_amount_usd?: number;
  multi_ticket_type?: "single" | "hacker_fare" | "virtual_interline";
}

export interface ChooseSavingsProperties {
  combined_discount_usd: number;
  applicable_combined_discount_usd: number;
  credits_amount_usd: number;
  applicable_credits_amount_usd?: number;
  credit_status?: string;
  applicable_offer_discount_usd?: number;
  product_type?: ProductType;
}

export const VIEW_TRIP_TRAVELERS = "view_trip_travelers";
export const EDIT_TRAVELERS = "edit_travelers";
export const SAVE_TRAVELER = "save_traveler";
export const CHOOSE_TRAVELER = "choose_traveler";

export const REVIEW_DETAILS = "review_details";

export const VIEW_CALENDAR = "view_calendar";
export const SEARCH_TRAVELERS_UPDATED = "search_travelers_updated";
export const SEARCH_CALENDAR_CHANGED = "search_calendar_changed";

export const VIEWED_AMENITY_REVIEW = "viewed_amenity_review";

export const BOOKING_CONFIRMATION = "booking_confirmation";

export const CONTINUE_TO_PAYMENT = "continue_to_payment";
export const CONTINUE_TO_PAYMENT_PRICE_FREEZE =
  "continue_to_payment_price_freeze";

export const CHANGED_TRIP_TYPE = "changed_trip_type";

export const SELECTED_ACTIVE_PF = "selected_active_pf";
export const VIEWED_ACTIVE_PF = "viewed_active_pf";
export const ENTERED_EXERCISE_FLOW = "entered_exercise_flow";

export const HOTEL_LOYALTY_SUBMITTED = "hotel_loyalty_submitted";

export const VIEWED_OFFER_REDEMPTION_CHOICE = "viewed_offer_redemption_choice";
export const TOGGLED_CREDITS_ON = "toggled_credits_on";
export const TOGGLED_CREDITS_OFF = "toggled_credits_off";
export const CONFIRMED_OFFER_REDEMPTION_CHOICE =
  "confirmed_offer_redemption_choice";

export enum RedemptionStatus {
  EXPLICITLY_ACCEPTED = "explicitly_accepted",
  EXPLICITLY_DECLINED = "explicitly_declined",
}

export const LOADED_FLIGHT_LIST = "loaded_flight_list";
