"use client";

import { Branding, BrandNames } from "@hopper-b2b/types";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/en-gb";
import "dayjs/locale/es";
import "dayjs/locale/pt";
import "dayjs/locale/fr";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import * as countries from "i18n-iso-countries";
import enCountries from "i18n-iso-countries/langs/en.json";
import esCountries from "i18n-iso-countries/langs/es.json";
import ptCountries from "i18n-iso-countries/langs/pt.json";
import frCountries from "i18n-iso-countries/langs/fr.json";
import i18next, { i18n } from "i18next";
import { initReactI18next } from "react-i18next";
import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import en_translations from "../locales/en/index";
import es_translations from "../locales/es/index";
import pt_translations from "../locales/pt/index";
import fr_translations from "../locales/fr/index";
import { I18nNamespace, Translation, TranslationLanguage } from "./types";
import { getRootLang } from "./utils/getRootLang";
import { getLangFromStorage } from "./utils/getLang";
import { I18nContext } from "./I18nContext";

// See https://github.com/i18next/react-i18next/issues/1587#issuecomment-1328676628
declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

export interface iI18nProvider {
  children: ReactNode;
  branding: Branding;
  defaultLng: string;
  localeParam?: string;
  tenantTranslation?: Translation;
}

export const I18nProvider: FC<iI18nProvider> = ({
  children,
  defaultLng,
  branding,
  tenantTranslation = {},
}: iI18nProvider) => {
  const [state, setState] = useState<i18n | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  // Deciding the language to use to initialize i18next
  //
  // It first looks at the user's preference stored either in the localStorage
  // or in the browser settings (getLangFromStorage) and check if the language is supported
  // by the app/tenant. Note that here it checks the whole tag of the language e.g. en-AU or es-MX.
  // LocalStorage can have a language tag when a language was selected by the language selector.
  //
  // If it is not supported, it tries the "language" part of the value (i.e. "en" for "en-AU" or
  // "es" for "es-MX") and check if it is supported.
  //
  // If it is still not supported, it uses the `defaultLng` and does the same check.
  //
  // Finally it uses the "language" part of the `defaultLng` which is the legacy behavior.
  const lang = useMemo(() => {
    function isLanguageSupported(lang: string) {
      return (
        lang in TranslationLanguage &&
        branding[lang] &&
        (branding[lang] as BrandNames)?.supportedLanguages?.some(
          (entry) => entry.key === lang
        )
      );
    }

    const userLang = getLangFromStorage();
    if (userLang && isLanguageSupported(userLang)) {
      return userLang;
    }
    const userLangRoot = userLang && getRootLang(userLang);
    if (
      userLangRoot !== userLang &&
      userLangRoot &&
      isLanguageSupported(userLangRoot)
    ) {
      return userLangRoot;
    }
    if (isLanguageSupported(defaultLng)) {
      return defaultLng;
    }
    return getRootLang(defaultLng);
  }, [defaultLng, branding]);

  const translationLanguage = useMemo(() => {
    // Look up supported languages in the following order:
    // `lang` -> branding.default -> "en"
    return lang in TranslationLanguage
      ? TranslationLanguage[lang as TranslationLanguage]
      : branding.default && branding.default in TranslationLanguage
      ? TranslationLanguage[branding.default as TranslationLanguage]
      : TranslationLanguage.en;
  }, [lang, branding.default]);

  useEffect(() => {
    i18next.use(initReactI18next).init(
      {
        resources: {},
        lng: lang,
        fallbackLng: branding.default || "en",
        returnNull: false,
      },
      (err) => {
        if (err) {
          throw new Error(err);
        }
        setState(i18next);
        setLoading(false);
      }
    );

    i18next?.services?.formatter?.addCached("daterange", (lng, options) => {
      const format = new Intl.DateTimeFormat(lng, {
        month: "short",
        day: "numeric",
        ...options,
      });
      return (val) => format.formatRange(val[0], val[1]);
    });

    // Set the locale for dayjs
    dayjs.locale(lang);
    dayjs.extend(LocalizedFormat);
  }, [branding.default, lang]);

  useEffect(() => {
    // Deep merge the values in the namespace.
    const deep = true;
    // Overwrite the values in the namespace.
    const overwrite = true;

    function addResourceBundles(language: string) {
      //Brand namespace
      i18next.addResourceBundle(
        language,
        I18nNamespace.brand,
        { ...(language in branding ? (branding[language] as BrandNames) : {}) },
        deep,
        overwrite
      );

      //Translation namespace
      const [translation] = getTranslations(language);
      i18next.addResourceBundle(
        language,
        I18nNamespace.translation,
        translation,
        deep,
        overwrite
      );

      if (tenantTranslation[language as TranslationLanguage]) {
        i18next.addResourceBundle(
          language,
          I18nNamespace.translation,
          tenantTranslation[language as TranslationLanguage],
          deep,
          overwrite
        );
      }
    }

    addResourceBundles(translationLanguage);
    // Load the resources from fallback language
    const fallbackLng = i18next.options.fallbackLng
      ? typeof i18next.options.fallbackLng === "string"
        ? i18next.options.fallbackLng
        : (i18next.options.fallbackLng as string[])[0]
      : undefined;
    if (!!fallbackLng && fallbackLng !== translationLanguage) {
      addResourceBundles(fallbackLng);
    }
  }, [
    // TODO: Find better way to trigger useEffect.
    // Note: As these are objects, dynamically updating values in the object would not trigger use effect.
    // No usecase currently
    branding,
    branding?.en,
    branding?.es,
    branding?.pt,
    branding?.fr,
    tenantTranslation,
    tenantTranslation?.en,
    tenantTranslation?.es,
    tenantTranslation?.pt,
    tenantTranslation?.fr,
    translationLanguage,
  ]);

  useEffect(() => {
    const [, countryList] = getTranslations(lang);
    countryList && countries.registerLocale(countryList);
  }, [lang]);

  return (
    <I18nContext.Provider value={state}>
      {loading ? null : children}
    </I18nContext.Provider>
  );
};

export const getTranslations = (
  language: string
): [unknown, countries.LocaleData] => {
  switch (language) {
    case TranslationLanguage.pt:
      return [pt_translations, ptCountries];
    case TranslationLanguage.es:
      return [es_translations, esCountries];
    case TranslationLanguage.en:
      return [en_translations, enCountries];
    case TranslationLanguage.fr:
      return [fr_translations, frCountries];
    default:
      return [en_translations, enCountries];
  }
};
