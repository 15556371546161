import { TrackEventRequest } from "@b2bportal/event-tracking-api";
import { IApiConfig } from "@hopper-b2b/types";
import {
  getMobileAppPlatform,
  isHopperAppWebView,
} from "@hopper-b2b/utilities";
import { axiosInstance } from "../AxiosInterceptor";
import { analyticsApiPrefix } from "../paths";

/**
 * @deprecated Do not use directly, use `@hopper-b2b/tracking` instead
 */
export const trackEvent = async (
  req: TrackEventRequest,
  config?: IApiConfig,
  keepAlive = false
): Promise<boolean> => {
  const analyticsApi = config?.analyticsApiPrefix || analyticsApiPrefix;
  const END_POINT = `${analyticsApi}/event`;

  ensureMobilePrefixIfRequired(req);
  try {
    const res = await axiosInstance.post(END_POINT, req, {
      headers: {
        "X-Keep-Alive": keepAlive ? keepAlive.toString() : null,
      },
    });
    return res.data;
  } catch (e) {
    console.error(e);
    // Don't reject promise to prevent ad block from interfering with api calls
    return false;
  }
};

/**
 * Sets the `c1_` or `m1_` prefix for the eventName if the app is running as a WebView in the Hopper App, otherwise, leave it as-is.
 */
const ensureMobilePrefixIfRequired = (req: TrackEventRequest) => {
  if (isHopperAppWebView()) {
    switch (getMobileAppPlatform()) {
      case "c1":
        req.eventName = `c1_${req.eventName}`;
        break;
      case "m1":
        req.eventName = `m1_${req.eventName}`;
        break;
      default:
        break;
    }
  }
};
