import { isHopperAppWebView } from "./mobileWebApp";

interface Window {
  Android?: {
    deferredActions?: (message: string) => void;
  };
  webkit?: {
    messageHandlers?: {
      deferredActions?: {
        postMessage: (message: string) => void;
      };
    };
  };
}

type RemoteUIActionObject = {
  key: string;
  event?: { [key: string]: unknown };
};

const iosHandler = ():
  | ((message: RemoteUIActionObject) => void | undefined)
  | undefined => {
  const winObj = window as unknown as Window;
  if (
    typeof winObj.webkit !== "undefined" &&
    winObj.webkit.messageHandlers &&
    winObj.webkit.messageHandlers.deferredActions
  ) {
    return (message: RemoteUIActionObject) =>
      winObj.webkit?.messageHandlers?.deferredActions?.postMessage(
        JSON.stringify(message)
      );
  } else {
    return undefined;
  }
};

const androidHandler = ():
  | ((message: RemoteUIActionObject) => void | undefined)
  | undefined => {
  const winObj = window as unknown as Window;
  if (typeof winObj.Android !== "undefined") {
    return (message: RemoteUIActionObject) =>
      winObj.Android?.deferredActions?.(JSON.stringify(message));
  } else {
    return undefined;
  }
};

const nativeHandler:
  | ((message: RemoteUIActionObject) => void | undefined)
  | undefined = isHopperAppWebView()
  ? iosHandler() ?? androidHandler()
  : undefined;

export const isRUIApp = Boolean(nativeHandler);

/**
 * If it is a remote UI app then run the action and return true, else return false
 * @param action - RUI action to be ran
 * @returns boolean - whether the action was ran
 */
export const runRemoteUIAction = function (
  action: RemoteUIActionObject
): boolean {
  if (!nativeHandler) {
    return false;
  }
  nativeHandler(action);
  return true;
};
