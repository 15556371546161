import { TransitionsOptions } from "@mui/material";

export const transitions: Required<
  Pick<TransitionsOptions, "duration" | "easing">
> = {
  duration: {
    shortest: 150,
    shorter: 200,
    short: 250,
    standard: 300,
    complex: 375,
    enteringScreen: 225,
    leavingScreen: 195,
  },
  easing: {
    easeInOut: "cubic-bezier(0.77,0,0.18,1)",
    easeOut: "cubic-bezier(0.17,0.84,0.44,1)",
    easeIn: "cubic-bezier(0.9,0.03,0.69,0.22)",
  },
} as const;
