import {
  Airline,
  BookedFlightItinerary,
  BookedFlightItineraryWithDepartureTime,
  FlightItinerarySegment,
  FlightItinerarySegmentStatus,
  FlightItinerarySlice,
  MultiTravelItinerary,
  SegmentAirlineInfo,
  SingleTravelItinerary,
  TravelItinerary,
  TravelItineraryEnum,
} from "@b2bportal/air-booking-api";
import {
  Segment,
  Slice,
  TripSlice,
  UtaCategory,
} from "@b2bportal/air-shopping-api";
import {
  AirRestrictionStatus,
  AirportMap,
  DullesUta,
  FareDetails,
  FareSegment,
  FareSliceDetails,
  FlightRatingsEnum,
  IConfirmationNumber,
  Restriction,
  TripDetails,
  TripSegment,
  getDepartureSlice,
  getDurationFromSegments,
  getReturnSlice,
} from "@hopper-b2b/types";
import dayjs from "dayjs";
import { TFunction } from "i18next";
import { isEqual, startCase, uniqWith } from "lodash-es";
import { getCalendarDayDifference, removeTimezone } from "./dayjsHelpers";

export const getSliceIndex = (
  departure: boolean,
  details: TripDetails | FareDetails
): number => {
  return details?.slices.findIndex((slice) =>
    departure ? slice.outgoing : !slice.outgoing
  );
};

export const getFilteredFareDetails = ({
  fareDetails,
  selectedFareClassFilters,
  isOutgoing,
  selectedFareId,
}: {
  fareDetails: FareDetails[];
  selectedFareClassFilters: Array<number>;
  isOutgoing: boolean;
  selectedFareId?: string;
}) => {
  // userSelectedFare, defaults to first fare in list if none selected
  const selectedFare = fareDetails.find((f) => f.id === selectedFareId);
  const sliceIndex = isOutgoing ? 0 : 1;
  const selectedFareRating =
    selectedFare?.slices[sliceIndex]?.fareShelf?.rating || -1;
  return fareDetails.filter((fare: FareDetails) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const fareSlice = fare.slices[sliceIndex]!;
    const fareSliceRating = getFareSliceRating(fareSlice);
    // Validate that fare slice has fareShelf defined
    if (fareSlice.fareShelf) {
      if (selectedFareClassFilters.length > 0) {
        // Filters applied
        return filterWithFareShelfRatings(
          selectedFareClassFilters,
          fareSliceRating
        );
      } else {
        // No filters applied
        if (selectedFareRating >= 0) {
          // If there's a selectedFare, show higher fares only
          return fareSliceRating >= selectedFareRating;
        }
      }
    }
    return true;
  });
};

export const getSortedFareDetails = (fareDetails: FareDetails[]) =>
  fareDetails.sort((a, b) => {
    const aPrice = a.paxPricings.reduce(
      (sum, p) => sum + (p.pricing?.total?.fiat.value || 0),
      0
    );
    const bPrice = b.paxPricings.reduce(
      (sum, p) => sum + (p.pricing?.total?.fiat.value || 0),
      0
    );
    if (aPrice) {
      if (bPrice) {
        if (aPrice < bPrice) {
          return -1;
        } else if (aPrice > bPrice) {
          return 1;
        }
      }
    }
    return 0;
  });

const filterWithFareShelfRatings = (
  selectedFareClassFilters: Array<number>,
  fareSliceRating: number
) => {
  const maxShelfRating = Math.max(...selectedFareClassFilters);

  return (
    selectedFareClassFilters.includes(fareSliceRating) ||
    fareSliceRating >= maxShelfRating
  );
};

const getFareSliceRating = (fareSlice: FareSliceDetails) =>
  typeof fareSlice?.fareShelf?.rating === "number" &&
  fareSlice.fareShelf.rating >= 0
    ? fareSlice.fareShelf.rating
    : -1;

export const getAirlineInfoFromBookedFlightItinerary = (
  flight: BookedFlightItineraryWithDepartureTime | null
): Array<SegmentAirlineInfo> => {
  if (!flight) {
    return [];
  }
  const { bookedItinerary } = flight;
  const { travelItinerary } = bookedItinerary;
  const { TravelItinerary } = travelItinerary;

  if (TravelItinerary === TravelItineraryEnum.SingleTravelItinerary) {
    const singleTravelItinerary = travelItinerary as SingleTravelItinerary;
    return singleTravelItinerary.slices.reduce(
      (locators, slice) => [
        ...locators,
        ...slice.segments.map((segment) => segment.marketingAirline),
      ],
      [] as Array<SegmentAirlineInfo>
    );
  } else if (TravelItinerary === TravelItineraryEnum.MultiTravelItinerary) {
    const multipleTravelItinerary = travelItinerary as MultiTravelItinerary;

    if (multipleTravelItinerary.locators?.children) {
      return multipleTravelItinerary.travelItineraries.reduce(
        (locators, itinerary) => [
          ...locators,
          ...itinerary.slices
            .map((slice) =>
              slice.segments.map((segment) => segment.marketingAirline)
            )
            .flat(),
        ],
        [] as Array<SegmentAirlineInfo>
      );
    }
  }
  return [];
};

export const getAirlineLocatorsFromBookedFlightItinerary = (
  flight: BookedFlightItineraryWithDepartureTime | null
): string => {
  const airlineInfo = getAirlineInfoFromBookedFlightItinerary(flight);
  const locators = Array.from(airlineInfo).map(
    (info) => `${info.code} - ${info.locator}`
  );
  return uniqWith(locators, isEqual).join(", ");
};

export const getHopperLocatorFromBookedFlightItinerary = (
  flight: BookedFlightItineraryWithDepartureTime | null
) =>
  (flight?.bookedItinerary?.travelItinerary as SingleTravelItinerary)?.locators
    ?.agent?.unscopedValue || "";

export const getEmptyRestrictionsText = (
  fareRating: number | undefined,
  translate: TFunction
) => {
  switch (fareRating) {
    case FlightRatingsEnum.basic:
      return translate("fareClass.basicDetails");
    case FlightRatingsEnum.standard:
      return translate("fareClass.standardDetails");
    case FlightRatingsEnum.enhanced:
      return translate("fareClass.enhancedDetails");
    case FlightRatingsEnum.premium:
      return translate("fareClass.premiumDetails");
    case FlightRatingsEnum.luxury:
      return translate("fareClass.luxuryDetails");
    default:
      return "";
  }
};

export const layoverDetails = ({
  tripDetails,
  airports,
  isOutgoing,
}: {
  airports: AirportMap;
  tripDetails: TripDetails;
  isOutgoing: boolean;
}) => {
  return tripDetails.slices
    .filter((slice) => isOutgoing === slice.outgoing)
    .map((slice) =>
      slice.segmentDetails.map(
        ({ airlineCode, originCode, destinationCode, airlineName }) => ({
          airlineCode,
          originCode,
          destinationCode,
          airlineName,
          originCity: airports[originCode]?.cityName,
          destinationCity: airports[destinationCode]?.cityName,
        })
      )
    )
    .flat();
};

export const getRestrictions = ({
  fareDetails,
  sliceIndex,
  translate,
  customerSupport,
  cfarSelected = false,
  chfarSelected = false,
}: {
  fareDetails: FareDetails;
  sliceIndex: number;
  translate: TFunction;
  customerSupport: boolean;
  cfarSelected: boolean | undefined;
  chfarSelected: boolean | undefined;
}): Restriction[] => {
  const restrictions: Restriction[] =
    fareDetails.slices[sliceIndex]?.amenitiesUtas?.utas.utas.map(
      (uta: DullesUta) => {
        const cfarIncluded =
          uta.category === UtaCategory.cancellation && cfarSelected;
        const chfarIncluded =
          uta.category === UtaCategory.advance_change && chfarSelected;

        const restriction: Restriction = {
          // TODO: the assessment field should probably be an enum
          symbol:
            uta.assessment === "benefit" || cfarIncluded || chfarIncluded
              ? AirRestrictionStatus.INCLUDED
              : uta.assessment === "fee"
              ? AirRestrictionStatus.PURCHASABLE
              : AirRestrictionStatus.UNAVAILABLE,
          // TODO: probably need a different type for category
          // TODO: i18n - test out with diff langs
          name: uta.category
            .split("-")
            .reduce((name: string, segment: string) => {
              return `${name}${name.length > 0 ? " " : ""}${startCase(
                segment
              )}`;
            }, ""),
          description: cfarIncluded
            ? translate("cfarOffers.restrictionsCfarText")
            : chfarIncluded
            ? translate("chOffer.restrictionsChfarText")
            : uta.description,
        };

        return restriction;
      }
    ) ?? [];

  if (customerSupport) {
    restrictions.push({
      symbol: AirRestrictionStatus.INCLUDED,
      name: translate("flightShopReview.customerSupportTitle"),
      description: translate("flightShopReview.customerSupportSubtitle"),
    });
  }
  return restrictions;
};

export const getPlusDays = (trip: TripSlice): number =>
  trip?.segmentDetails
    .filter((s) => s.plusDays && s.plusDays > 0)
    .reduce((total, segment) => total + (segment.plusDays ?? 0), 0);

// for individual pieces of a tripSlice
export const getPlusDaysOnSlice = (slice: Slice): number => {
  return slice?.segments
    .filter((segment) => segment.plusDays && segment.plusDays > 0)
    .reduce((total, segment) => total + (segment.plusDays ?? 0), 0);
};

export const getPlusDaysOnSliceWithDates = (
  departureDate: string,
  arrivalDate: string
) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const departure = removeTimezone(departureDate)!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const arrival = removeTimezone(arrivalDate)!;
  return getCalendarDayDifference(departure, arrival);
};

// mixed cabin is true if the following conditions are met:
// - fareShelf rating of 3 or above AND one of the below:
//  - the shortBrandName is PREMIUM ECONOMY AND one of the segment has a cabinClassName of ECONOMY
//  - the shortBrandName is FIRST or BUSINESS (not premium economy) AND one of the segment includes the word "Economy" (PREMIUM ECONOMY/ECONOMY)
// note: checking using the shortBrandName instead of the cabinClassName because the cabinClassName mapping is currently incorrect in same cases (we were getting back a cabinClassName of FIRST instead of PREMIUM ECONOMY)
export const getIsMixedClass = (
  slice: FareSliceDetails | FlightItinerarySlice
) => {
  if (!slice?.fareShelf || slice?.fareShelf?.rating >= 3) {
    let segments: FareSegment[] | FlightItinerarySegment[];

    if ("fareDetails" in slice) {
      ({ segments } = slice.fareDetails);
    } else {
      ({ segments } = slice);
    }

    if (segments.length <= 1) return false;

    const lookupCabinClass = segments[0]?.cabinClassName;

    if (lookupCabinClass && lookupCabinClass.length === 1) {
      // unioned segments type needs to split up to fix a TS error
      if (isFareSegmentArray(segments)) {
        return segments.every(
          (segment) => segment.cabinClassName === lookupCabinClass
        );
      }

      return segments.every(
        (segment) => segment.cabinClassName === lookupCabinClass
      );
    }

    return segments.some((segment: FareSegment | FlightItinerarySegment) => {
      const { brandName, shortBrandName } = slice.fareShelf ?? {};
      const { cabinClassName } = segment;

      if (brandName?.includes("First") && shortBrandName === "Premium") {
        return cabinClassName === "Economy";
      }

      return cabinClassName?.includes("Economy");
    });
  }

  return false;
};

export function getSliceDuration(slice: FlightItinerarySlice) {
  const duration = getDurationFromSegments(
    slice.segments,
    (departure, arrival) => dayjs(arrival).diff(dayjs(departure), "m")
  );
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;

  return dayjs.duration({ hours, minutes }).format("H[h] m[m]");
}

/**
 * @description Gets the originally scheduled slice times to be striked through
 */
export function getScheduleChangeSliceTime(slice: FlightItinerarySlice) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const firstSeg = slice.segments.at(0)!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const lastSeg = slice.segments.at(-1)!;
  const departure = dayjs(removeTimezone(firstSeg.scheduledDeparture));
  const arrival = dayjs(removeTimezone(lastSeg.scheduledArrival));

  return `${departure.format("h:mm A")} - ${arrival.format("h:mm A")}`;
}

export function getSliceTime(slice: FlightItinerarySlice) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const firstSeg = slice.segments.at(0)!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const lastSeg = slice.segments.at(-1)!;
  const dep =
    firstSeg.zonedUpdatedDeparture ||
    firstSeg.updatedDeparture ||
    firstSeg.zonedScheduledDeparture ||
    firstSeg.scheduledDeparture;
  const ret =
    lastSeg.zonedUpdatedArrival ||
    lastSeg.updatedArrival ||
    lastSeg.zonedScheduledArrival ||
    lastSeg.scheduledArrival;
  const departure = dayjs(removeTimezone(dep));
  const returnTime = dayjs(removeTimezone(ret));

  return `${departure.format("h:mm A")} - ${returnTime.format("h:mm A")}`;
}

export function isFareSegmentArray(
  segments: FareSegment[] | FlightItinerarySegment[]
): segments is FareSegment[] {
  return !!segments[0] && "fareClass" in segments[0];
}

export const getAirlinesCountSegment = (segment: Segment[] | TripSegment[]) => {
  const set = new Set(
    (segment ?? []).map((segment) =>
      typeof segment.marketingAirline === "string"
        ? segment.marketingAirline
        : segment.marketingAirline.code
    )
  );
  return set.size - 1;
};

export const getVirtualInterlineLayovers = (
  fareDetails: FareDetails,
  outbound: boolean
) => {
  return fareDetails.slices[outbound ? 0 : 1]?.fareDetails.segments.filter(
    (s) => s.isSelfTransferLayover
  ).length;
};

export interface IConfirmationModalLabels {
  confirmationCode: string;
  return: string;
  outbound: string;
  originToDestination: (origin: string, destination: string) => string;
}

export interface IGetConfirmationNumberProps {
  segment: FlightItinerarySegment;
  airlineMap: {
    [key: string]: Airline | undefined;
  };
  isReturn?: boolean;
  labels?: IConfirmationModalLabels;
}

export const getConfirmationNumber = ({
  segment,
  airlineMap,
  isReturn,
  labels,
}: IGetConfirmationNumberProps): IConfirmationNumber | false => {
  const selectedAirline = airlineMap[segment.marketingAirline.code];
  if (!selectedAirline) return false;
  const returnLabel = labels?.return;
  const outboundLabel = labels?.outbound;
  const originToDestinationLabel = labels?.originToDestination;

  const label =
    returnLabel && outboundLabel && originToDestinationLabel
      ? `${selectedAirline.displayName} ${
          isReturn ? returnLabel : outboundLabel
        }: ${
          originToDestinationLabel &&
          originToDestinationLabel(
            segment.origin.locationCode,
            segment.destination.locationCode
          )
        }`
      : selectedAirline.displayName;

  return {
    label: label,
    locator: segment.marketingAirline.locator || "",
    redirectUrl: selectedAirline.webLinks.manageBooking || "",
  };
};

export const getConfirmationNumbers = ({
  flight,
  airlineMap,
  labels,
}: {
  flight: BookedFlightItineraryWithDepartureTime;
  airlineMap: { [key: string]: Airline | undefined };
  labels?: IConfirmationModalLabels;
}): IConfirmationNumber[] => {
  const { bookedItinerary } = flight;
  const { travelItinerary } = bookedItinerary;
  const { TravelItinerary } = travelItinerary;

  const confirmationNumbers: IConfirmationNumber[] = [];

  if (
    (travelItinerary as SingleTravelItinerary)?.locators?.agent?.unscopedValue
  ) {
    confirmationNumbers.push({
      label: labels ? labels.confirmationCode : "Hopper Travel",
      locator:
        (travelItinerary as SingleTravelItinerary).locators?.agent?.value || "",
      redirectUrl: "https://www.hopper.com/",
      unscopedValue:
        (travelItinerary as SingleTravelItinerary).locators?.agent
          ?.unscopedValue || "",
    });
  }

  if (TravelItinerary === TravelItineraryEnum.SingleTravelItinerary) {
    const singleTravelItinerary = travelItinerary as SingleTravelItinerary;
    singleTravelItinerary.slices.forEach((slice, sliceIndex) => {
      slice.segments.forEach((segment) => {
        const confirmationNumber = getConfirmationNumber({
          segment,
          airlineMap,
          isReturn: sliceIndex !== 0,
          labels,
        });
        if (confirmationNumber) {
          confirmationNumbers.push(confirmationNumber);
        }
      });
    });
  }
  if (TravelItinerary === TravelItineraryEnum.MultiTravelItinerary) {
    const multipleTravelItinerary = travelItinerary as MultiTravelItinerary;

    if (multipleTravelItinerary.locators?.children) {
      multipleTravelItinerary.travelItineraries.forEach((itinerary) => {
        itinerary.slices.forEach((slice, sliceIndex) => {
          slice.segments.forEach((segment) => {
            const confirmationNumber = getConfirmationNumber({
              segment,
              airlineMap,
              isReturn: sliceIndex !== 0,
              labels,
            });
            if (confirmationNumber) {
              confirmationNumbers.push(confirmationNumber);
            }
          });
        });
      });
    }
  }
  return confirmationNumbers;
};

export const getLocatorsWithLabelFromItinerary = ({
  itinerary,
  airlineMap,
}: {
  itinerary: TravelItinerary;
  airlineMap: { [key: string]: Airline | undefined };
}): Array<{ locator: string; label: string }> => {
  const { TravelItinerary } = itinerary;

  const confirmationNumbers: Array<{ locator: string; label: string }> = [];

  if (TravelItinerary === TravelItineraryEnum.SingleTravelItinerary) {
    const singleTravelItinerary = itinerary as SingleTravelItinerary;
    singleTravelItinerary.slices.forEach((slice) => {
      slice.segments.forEach((segment) => {
        const confirmationNumber = getLocatorWithLabelFromSegment({
          segment,
          airlineMap,
        });
        if (confirmationNumber) {
          confirmationNumbers.push(confirmationNumber);
        }
      });
    });
  }
  if (TravelItinerary === TravelItineraryEnum.MultiTravelItinerary) {
    const multipleTravelItinerary = itinerary as MultiTravelItinerary;

    if (multipleTravelItinerary.locators?.children) {
      multipleTravelItinerary.travelItineraries.forEach((itinerary) => {
        itinerary.slices.forEach((slice) => {
          slice.segments.forEach((segment) => {
            const confirmationNumber = getLocatorWithLabelFromSegment({
              segment,
              airlineMap,
            });
            if (confirmationNumber) {
              confirmationNumbers.push(confirmationNumber);
            }
          });
        });
      });
    }
  }
  return uniqWith(confirmationNumbers, (a, b) => a.locator === b.locator);
};

export const getLocatorWithLabelFromSegment = ({
  segment,
  airlineMap,
}: {
  segment: FlightItinerarySegment;
  airlineMap: { [key: string]: Airline | undefined };
}): { locator: string; label: string } | false => {
  const selectedAirline = airlineMap[segment.marketingAirline.code];
  if (!selectedAirline) return false;

  return {
    label: selectedAirline.displayName,
    locator: segment.marketingAirline.locator || "",
  };
};

export const sliceHasScheduleChange = (
  bookedItinerary: BookedFlightItinerary,
  isOutgoing = false
) => {
  const slice = isOutgoing
    ? getDepartureSlice(bookedItinerary)
    : getReturnSlice(bookedItinerary);

  if (slice) {
    return slice.segments.some(
      (s) =>
        s.status === FlightItinerarySegmentStatus.ConfirmedPendingNewChange ||
        s.status === FlightItinerarySegmentStatus.UnMapped ||
        s.status === FlightItinerarySegmentStatus.UnMappedPersisted
    );
  }

  return false;
};
