"use client";

import { useI18nContext, useI18nCurrency } from "@hopper-b2b/i18n";
import {
  AirRestrictionStatus,
  AlgomerchTag,
  DullesAmenityCanExist,
  DullesUta,
  FareDetails,
  FareScore,
  FlightTags,
  getTags,
  mapI18nAlgomerchText,
  PolicyAssessmentEnum,
  TripDetails,
  UtasPolicyCategory,
} from "@hopper-b2b/types";
import { ActionButton } from "@hopper-b2b/ui-core";
import { useTenantIcons } from "@hopper-b2b/utilities";
import { Box, Button, Grid, Popover, Typography } from "@mui/material";
import clsx from "clsx";
import { MouseEvent, useCallback, useEffect, useState } from "react";
import InfoIcon from "../../../assets/icons/common/info-icon.svg";
import { Slot } from "../../Slots";
import { Tag } from "../../Tag";
import { IFareDetailsCardProps, IRestrictionProps } from "../FareDetailsCard";
import { Amenity } from "./Amenity";
import styles from "./FareCardDetails.module.scss";

export type FareCardDetailsProps = Pick<
  IFareDetailsCardProps,
  | "isMobile"
  | "isOutgoing"
  | "fareDetails"
  | "fareNotice"
  | "onClick"
  | "onAlgomerchClick"
  | "getEmptyRestrictionsText"
  | "getFlightShopFareSlice"
> & {
  fare: FareDetails;
  index: number;
  anchorEl: HTMLElement;
  isLast: boolean;
  getSelectedFareId: () => string;
  tripDetails?: TripDetails;
};

export const FareCardDetails = ({
  fare,
  index,
  anchorEl,
  isLast,
  isMobile,
  isOutgoing,
  fareDetails,
  fareNotice,
  onClick,
  onAlgomerchClick,
  getSelectedFareId,
  getEmptyRestrictionsText,
  getFlightShopFareSlice,
}: FareCardDetailsProps) => {
  const { t } = useI18nContext();
  const { formatFiatCurrency } = useI18nCurrency();
  const tenantIcons = useTenantIcons();

  const [hackerFareAnchorEl, setHackerFareAnchorEl] =
    useState<HTMLElement | null>(null);
  const [showAllAmenities, setShowAllAmenities] = useState<boolean>(false);

  const fareIndex = fare.slices.findIndex((slice) =>
    isOutgoing ? slice.outgoing : !slice.outgoing
  );
  const fareSlice = fare.slices[fareIndex];

  const shopFareSlice = getFlightShopFareSlice(fare.id);

  const utas = fareSlice.amenitiesUtas?.utas;
  const changePolicy =
    utas?.changePolicy?.anytime ?? utas?.changePolicy?.beforeDeparture;

  const cancelPolicy =
    utas?.cancellationPolicy?.anytime ??
    utas?.cancellationPolicy?.beforeDeparture;

  const restrictions: IRestrictionProps[] = [];

  const utaToSymbol = (uta: DullesUta) => {
    const assessment = uta.assessment.toLowerCase();
    return assessment === "benefit"
      ? AirRestrictionStatus.INCLUDED
      : assessment === "fee"
      ? AirRestrictionStatus.PURCHASABLE
      : AirRestrictionStatus.UNAVAILABLE;
  };

  const assessmentToSymbol = (assessment) => {
    return assessment === PolicyAssessmentEnum.Benefit
      ? AirRestrictionStatus.INCLUDED
      : AirRestrictionStatus.UNAVAILABLE;
  };

  restrictions.push(
    ...(fareSlice.amenitiesUtas?.utas.utas.map((uta: DullesUta) => {
      let restriction: IRestrictionProps;
      if (uta.category === UtasPolicyCategory.AdvanceChange) {
        restriction = {
          symbol: changePolicy
            ? assessmentToSymbol(changePolicy.assessment)
            : utaToSymbol(uta),
          description: changePolicy?.description ?? uta.description,
        };
      } else if (uta.category === UtasPolicyCategory.Cancellation) {
        restriction = {
          symbol: cancelPolicy
            ? assessmentToSymbol(cancelPolicy.assessment)
            : utaToSymbol(uta),
          description: cancelPolicy?.description ?? uta.description,
        };
      } else {
        restriction = {
          // TODO: the assessment field should probably be an enum
          symbol: utaToSymbol(uta),
          // TODO: probably need a different type for category
          description: uta.description,
        };
      }

      return restriction;
    }) ?? [])
  );

  // note: if "cancellation" is not found in utas.utas, push in cancelPolicy if available
  if (
    !utas?.utas.find(
      (policy) => policy.category === UtasPolicyCategory.Cancellation
    ) &&
    cancelPolicy
  ) {
    restrictions.unshift({
      symbol: assessmentToSymbol(cancelPolicy.assessment),
      description: cancelPolicy?.description,
    });
  }

  // note: if "advance-change" is not found in utas.utas, push in changePolicy if available
  if (
    !utas?.utas.find(
      (policy) => policy.category === UtasPolicyCategory.AdvanceChange
    ) &&
    changePolicy
  ) {
    restrictions.unshift({
      symbol: assessmentToSymbol(changePolicy.assessment),
      description: changePolicy?.description,
    });
  }

  if (fareSlice.amenitiesUtas?.amenities) {
    restrictions.push(
      ...Object.keys(fareSlice.amenitiesUtas.amenities).map((key) => {
        const amenitity: DullesAmenityCanExist =
          fareSlice.amenitiesUtas?.amenities?.[key];
        const symbol = amenitity.exists
          ? AirRestrictionStatus.INCLUDED
          : AirRestrictionStatus.GENERIC;
        return {
          symbol: symbol,
          description: `${t(`amenitiesCategories.${key}`)} - ${
            amenitity.info.displayText
          }`,
        };
      })
    );
  }

  useEffect(() => {
    setShowAllAmenities(false);
  }, []);

  const handleSeeMoreOnClick = useCallback(() => {
    setShowAllAmenities(true);
  }, []);

  const handleHackerFarePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setHackerFareAnchorEl(event.currentTarget);
  };

  const handleHackerFarePopoverClose = () => {
    setHackerFareAnchorEl(null);
  };

  const onFareClicked = useCallback(() => {
    if (onClick) {
      setShowAllAmenities(false);
      onClick(fare);
    }
  }, [fare, onClick]);

  const getTagText = useCallback(
    (fareScore: FareScore | FlightTags) => {
      const tagLimit = 2;
      const tagSeperator = ", ";
      const tags = getTags(fareScore);
      const filteredTags = tags
        .filter((tag: AlgomerchTag) => tag !== AlgomerchTag.Fastest)
        .map((tag: AlgomerchTag) => t(mapI18nAlgomerchText[tag]))
        .slice(0, tagLimit);
      return filteredTags.join(tagSeperator);
    },
    [t]
  );

  const tagText = shopFareSlice ? getTagText(shopFareSlice.tags) : "";

  const paxPricing = fare.paxPricings[0].pricing;
  const totalPriceString = formatFiatCurrency(
    {
      value: paxPricing.baseAmount.fiat.value + paxPricing.taxAmount.fiat.value,
      currencyCode: paxPricing.baseAmount.fiat.currencyCode,
    },
    {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }
  );
  const showFareNotice = fareNotice?.find((n) => n?.id === fare.id);

  return (
    <Box
      className={clsx(styles.card, { [styles.last]: isLast }, "fare-card")}
      p={2}
    >
      <Grid container spacing={3} alignItems="flex-start" wrap="nowrap">
        {tenantIcons && tenantIcons.fareRatings && (
          <Grid item>
            <img
              width={115}
              src={tenantIcons.fareRatings[fareSlice.fareShelf.rating]}
              alt={fareSlice.fareShelf.brandName}
            />
          </Grid>
        )}
        <Grid item xs>
          <Grid container spacing={2} alignItems="center" wrap="nowrap">
            <Grid item xs={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Typography variant="h5">
                    {fareSlice.fareShelf?.shortBrandName}
                  </Typography>
                </Grid>
                {tagText && (
                  <Grid item>
                    <Button className="algomerch-button-tag" size="small">
                      <Tag
                        className={styles.tags}
                        label={tagText}
                        onClick={() => onAlgomerchClick?.(tagText)}
                      />
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs />
            <Grid item>
              <ActionButton
                onClick={onFareClicked}
                variant={
                  getSelectedFareId() === fare.id ? "contained" : "outlined"
                }
                size="medium"
                message={t?.("continueFor", { price: totalPriceString })}
              />
            </Grid>
          </Grid>
          <Slot id="fare-details-card-policy" fareId={fare.id} />
          {restrictions && (
            <div className="fare-details-card-policy-item">
              {restrictions.length === 0 && (
                <Box my={2}>
                  <Typography variant="body1">
                    {getEmptyRestrictionsText(fareSlice.fareShelf?.rating, t)}
                  </Typography>
                </Box>
              )}
              {fareDetails.length === 1 || showAllAmenities ? (
                <>
                  {restrictions.map(
                    (restriction: IRestrictionProps, index: number) => (
                      <Amenity
                        key={index}
                        anchorEl={anchorEl}
                        restriction={restriction}
                        fareId={fare.id}
                        handleSeeMoreOnClick={handleSeeMoreOnClick}
                      />
                    )
                  )}
                </>
              ) : (
                <>
                  {restrictions.slice(0, 4).map((restriction, idx) => {
                    const isLast = restrictions.length > 4 && idx === 4 - 1;
                    return (
                      <Amenity
                        key={idx}
                        anchorEl={anchorEl}
                        restriction={restriction}
                        fareId={fare.id}
                        index={index}
                        isLast={isLast}
                        count={restrictions.length - 4}
                        handleSeeMoreOnClick={handleSeeMoreOnClick}
                      />
                    );
                  })}
                </>
              )}
            </div>
          )}

          {!!showFareNotice && (
            <>
              <Typography
                className={clsx("fare-notice", styles.fareNotice)}
                variant="body2"
                onClick={(event) => handleHackerFarePopoverOpen(event)}
              >
                {showFareNotice.message} <img src={InfoIcon} alt="info icon" />
              </Typography>
              <Popover
                id="mouse-over-popover"
                className="hacker-fare-popover"
                open={!!hackerFareAnchorEl}
                anchorEl={hackerFareAnchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handleHackerFarePopoverClose}
              >
                <Typography
                  className={clsx("farenotice-info", styles.fareNoticeInfo)}
                  variant="body2"
                  onMouseLeave={handleHackerFarePopoverClose}
                >
                  {showFareNotice.tooltipCopy}
                </Typography>
              </Popover>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
