export const B2B_LOCALE_PREFERENCE_KEY = "b2b-locale-preference";
export const LANGUAGE_SEARCH_PARAM = "lang";

/**
 * Please use export from libs/i18n/src/utils/getLang.ts
 *
 * Get full language with country, if exists. Check local storage first since
 * some tenants (Nequi or Volaris) can select their language. If there's nothing in local
 * storage, check the navigator, or undefined
 */
export function internalGetLangFromStorage(
  useNavigator = true
): string | undefined {
  let fromStorage;
  let fromCookie;
  try {
    fromCookie = readLocaleCookie();
    fromStorage = localStorage?.getItem(B2B_LOCALE_PREFERENCE_KEY);
  } catch (e) {
    //console.warn("storage unavailable")
  }

  return (
    fromCookie ||
    fromStorage ||
    (useNavigator &&
      typeof window !== "undefined" &&
      navigator &&
      navigator?.language) ||
    undefined
  );
}

/**
 * Google Flights will put the language in the search params when linking to our flights.
 */
function getLangFromSearchParam(): string | undefined {
  // make sure we do not break SSR when trying to access window
  if (typeof window === "undefined") return undefined;
  const param = new URLSearchParams(window?.location?.search).get(
    LANGUAGE_SEARCH_PARAM
  );
  return param || undefined;
}

/**
 * Please use export from libs/i18n/src/utils/getLang.ts
 *
 * Get full language with country, if exists. Check local storage first since
 * some tenants (Nequi or Volaris) can select their language. If there's nothing in local
 * storage, check the navigator, then defaultLng, fallback to en
 */
export const internalGetLang = (lang?: string, useNavigator = true): string => {
  const languageFromCookie = readLocaleCookie();
  const languageFromStorage = internalGetLangFromStorage(useNavigator);
  const languageFromSearchParam = getLangFromSearchParam();

  return (
    languageFromSearchParam ||
    languageFromCookie ||
    languageFromStorage ||
    lang ||
    "en"
  );
};

/**
 * Rewrites query param when setting a new language.
 */
const updateSearchParam = (lang: string) => {
  if (typeof window !== "undefined") {
    const url = new URL(window.location.href);
    if (url.searchParams?.get(LANGUAGE_SEARCH_PARAM)) {
      url.searchParams.set(LANGUAGE_SEARCH_PARAM, lang);
      window.history.replaceState({}, "", url);
    }
  }
};

/**
 * Please use export from libs/i18n/src/utils/getLang.ts
 */
export const internalSetLang = (lang: string) => {
  const languageFromCookie = readLocaleCookie();
  const language =
    new Intl.Locale(lang).language ||
    (languageFromCookie
      ? new Intl.Locale(languageFromCookie).language
      : undefined);
  updateSearchParam(lang);

  if (language) {
    writeCookie(language);
  }
};

const readLocaleCookie = () => {
  let cookieValue;
  try {
    cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith(B2B_LOCALE_PREFERENCE_KEY))
      ?.split("=")[1];
  } catch (e) {
    //console.warn("cookie unavailable")
  }

  return cookieValue;
};

/**
 * Please use export from libs/i18n/src/utils/getLang.ts
 */
export const internalUpdateLang = () => {
  internalSetLang(internalGetLang());
};

const writeCookie = (lang: string) => {
  try {
    document.cookie = `${B2B_LOCALE_PREFERENCE_KEY}=${lang}; path=/`;
  } catch (e) {
    //console.warn("cookie unavailable")
  }
};
