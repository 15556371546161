"use client";

import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from "react";
import { isHopperAppWebView, login } from "../mobileWebApp";

export enum SessionStateEnum {
  Anonymous = "Anonymous",
  Guest = "Guest",
  Authenticated = "Authenticated",
}
export type ISessionContextProps = {
  sessionType?: SessionStateEnum;
  isLoggedIn?: boolean;
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  countryCode?: string;
  isLoginModalOpen?: boolean;
  isFirstSession?: boolean;
  loginContextHeader?: string;
  setLoginContextHeader?: (val?: string) => void;
  setLoginModalOpen?: (val: boolean) => void;
  setSessionContext?: (val: ISessionContextProps) => void;
};

export interface ISessionContextProvider {
  sessionContext: ISessionContextProps;
}

export const SessionContext = createContext<ISessionContextProps>({
  sessionType: SessionStateEnum.Anonymous,
  isLoggedIn: false,
});

export const useSessionContext = () => {
  const ctx = useContext(SessionContext);
  if (ctx === undefined)
    throw new Error(`must be used within a SessionContextProvider`);
  return { ...ctx };
};

export const useSetSessionContext = () => {
  const ctx = useContext(SessionContext);
  if (ctx === undefined)
    throw new Error(`must be used within a SessionContextProvider`);
  return useCallback(
    (context: ISessionContextProps) => {
      ctx?.setSessionContext?.(context);
    },
    [ctx]
  );
};

export const useOpenAuthModal = () => {
  const ctx = useContext(SessionContext);
  if (ctx === undefined)
    throw new Error(`must be used within a SessionContextProvider`);

  return useCallback(() => {
    ctx?.setLoginContextHeader?.(undefined);
    if (isHopperAppWebView()) {
      // Webview always shows native login and reloads the page onComplete
      // If needed an optional onComplete param can be added to useOpenAuthModal
      login("lodging-web", "window.location.reload();");
    } else {
      ctx?.setLoginModalOpen?.(true);
    }
  }, [ctx]);
};

export const useOpenAuthModalWithTitle = () => {
  const ctx = useContext(SessionContext);
  if (ctx === undefined)
    throw new Error(`must be used within a SessionContextProvider`);

  return useCallback(
    (title?: string) => {
      if (title) {
        ctx?.setLoginContextHeader?.(title);
      } else ctx?.setLoginContextHeader?.(undefined);

      if (isHopperAppWebView()) {
        // Webview always shows native login and reloads the page onComplete
        // If needed an optional onComplete param can be added to useOpenAuthModal
        login("lodging-web", "window.location.reload();");
      } else {
        ctx?.setLoginModalOpen?.(true);
      }
    },
    [ctx]
  );
};

export const useCloseAuthModal = () => {
  const ctx = useContext(SessionContext);
  if (ctx === undefined)
    throw new Error(`must be used within a SessionContextProvider`);
  return useCallback(() => {
    ctx?.setLoginModalOpen?.(false);
  }, [ctx]);
};

export const useToggleAuthModal = () => {
  const ctx = useContext(SessionContext);
  if (ctx === undefined)
    throw new Error(`must be used within a SessionContextProvider`);
  return useCallback(() => {
    ctx?.setLoginModalOpen?.(!ctx.isLoginModalOpen);
  }, [ctx]);
};

export const useIsAuthModalOpen = () => {
  const ctx = useContext(SessionContext);
  if (ctx === undefined)
    throw new Error(`must be used within a SessionContextProvider`);
  return ctx?.isLoginModalOpen === true;
};

export const useIsSessionAuthenticated = () => {
  const sessionContext = useSessionContext();

  return sessionContext.sessionType === SessionStateEnum.Authenticated;
};

export const SessionContextProvider = ({
  children,
  sessionContext: intialSessionContext,
}: PropsWithChildren<ISessionContextProvider>) => {
  const [isLoginModalOpen, setLoginModalOpen] = useState(
    intialSessionContext?.isLoginModalOpen
  );

  const [sessionContext, setSessionContext] = useState({});
  const [loginContextHeader, setLoginContextHeader] = useState<
    string | undefined
  >(undefined);

  return (
    <SessionContext.Provider
      value={{
        ...intialSessionContext,
        ...sessionContext,
        isLoginModalOpen,
        setLoginModalOpen,
        loginContextHeader,
        setLoginContextHeader,
        setSessionContext,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
